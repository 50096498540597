import { ModalAction as Modal } from '@get-e/react-components';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { REPEATS } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';
import { BlockedPeriodMessage } from '../../../helpers/repeats';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1.875rem',
        color: COLORS.BLUE,
    },
    buttonFixedWidth: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '0.3rem !important',
    },
    cancelButton: {
        width: `${BUTTON_WIDTH}`,
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
});

interface ConfirmDeleteModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    repeats: REPEATS;
    blockedPeriodMessage: BlockedPeriodMessage;
    submitDelete: () => Promise<void>;
    isSaving?: boolean;
    isFieldDisabled?: boolean;
}

const ConfirmDeleteModal = ({
    isModalOpen,
    onClose,
    repeats,
    blockedPeriodMessage,
    submitDelete,
    isSaving,
    isFieldDisabled,
}: ConfirmDeleteModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={submitDelete}
            title={t('pages.availability.deleteBlockedPeriod')}
            confirmButtonLabel={t('buttonName.delete')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving || isFieldDisabled}
        >
            <Typography
                sx={{
                    color: COLORS.BLACK,
                    marginBottom: '1rem !important',
                }}
            >
                {t('pages.availability.confirmDeleteBlockPeriodMessage1')}:
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box
                    display="flex"
                    alignItems="center"
                    margin="0 0.5rem 0.5rem -0.3rem"
                    color={COLORS.SLATE_GREY}
                >
                    <Tooltip title={t('pages.availability.startsOn')} arrow>
                        <FlagOutlinedIcon />
                    </Tooltip>
                    <Typography className={classes.mobileContent}>
                        {blockedPeriodMessage.part1}{' '}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    margin="0 0.5rem 0.5rem -0.3rem"
                    color={COLORS.SLATE_GREY}
                >
                    <Tooltip title={t('pages.availability.endsOn')} arrow>
                        <SportsScoreOutlinedIcon />
                    </Tooltip>
                    <Typography className={classes.mobileContent}>
                        {blockedPeriodMessage.part2}
                    </Typography>
                </Box>
                {repeats !== REPEATS.DO_NOT_REPEAT && (
                    <Box
                        display="flex"
                        alignItems="center"
                        margin="0 0.5rem 0 -0.3rem"
                        color={COLORS.SLATE_GREY}
                    >
                        <Tooltip title={t('pages.availability.repeatingRules')} arrow>
                            <RepeatOutlinedIcon />
                        </Tooltip>
                        <Typography className={classes.mobileContent}>
                            {blockedPeriodMessage.part3}
                        </Typography>
                    </Box>
                )}
                <Typography
                    sx={{
                        color: COLORS.BLACK,
                        marginBottom: '0.5rem !important',
                        marginTop: '1rem !important',
                    }}
                >
                    {t('pages.availability.confirmDeleteBlockPeriodMessage2')}
                </Typography>
                <Typography
                    sx={{
                        color: COLORS.BLACK,
                        fontWeight: 700,
                    }}
                >
                    {t('doYouWantToProceed')}
                </Typography>
            </Box>
        </Modal>
    );
};

export default ConfirmDeleteModal;
