import { LabeledData, DropdownMenu } from '@get-e/react-components';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { ChangeStatus, Vehicle } from '../api/types';
import SmallVehicleImage from './SmallVehicleImage';

const useStyles = makeStyles({
    image: {
        height: '62px',
        width: '182px',
    },
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
    cardContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    mili: {
        '& .MuiGrid-item ': {
            paddingTop: '0.3rem',
            '& > span': {
                fontSize: '.875rem',
                margin: 0,
            },
        },
    },
});

interface VehicleCardProps {
    vehicle: Vehicle;
    onToggleStatus: (vehicle: Vehicle) => void;
    onEdit: (vehicle: Vehicle) => void;
    onDuplicateClick: (vehicle: Vehicle) => void;
}

const VehicleCard = ({ vehicle, onToggleStatus, onEdit, onDuplicateClick }: VehicleCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onActionItemClick = useCallback((action: ChangeStatus) => {
        switch (action) {
            case ChangeStatus.EDIT:
                onEdit(vehicle);
                break;
            case ChangeStatus.DUPLICATE:
                onDuplicateClick(vehicle);
                break;
            case ChangeStatus.DEACTIVATE:
                onToggleStatus(vehicle);
                break;
            case ChangeStatus.ACTIVATE:
                onToggleStatus(vehicle);
                break;
            default:
                throw new Error('Driver action not supported');
        }
    }, [onDuplicateClick, onEdit, onToggleStatus, vehicle]);

    return (
        <Grid container className={classes.cardContainer} onClick={() => {}}>
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {vehicle?.licensePlate}
                </Typography>
                <DropdownMenu
                    options={[
                        {
                            id: 1,
                            label: t('buttonName.edit'),
                            icon: EditIcon,
                            iconColor: COLORS.BLUE_DARK,
                            labelColor: COLORS.BLUE_DARK,
                            onClick: () => {
                                onActionItemClick(ChangeStatus.EDIT);
                            },
                        },
                        {
                            id: 2,
                            label: t('duplicate'),
                            icon: ContentCopyIcon,
                            iconColor: COLORS.BLUE_DARK,
                            labelColor: COLORS.BLUE_DARK,
                            onClick: () => {
                                onActionItemClick(ChangeStatus.DUPLICATE);
                            },
                        },
                        {
                            id: 3,
                            label: t(vehicle.isArchived ? 'buttonName.activate' : 'buttonName.deactivate'),
                            icon: vehicle.isArchived ? NoCrashIcon : CarRepairIcon,
                            iconColor: vehicle.isArchived ? COLORS.BLUE_DARK : COLORS.RED,
                            labelColor: vehicle.isArchived ? COLORS.BLUE_DARK : COLORS.RED,
                            onClick: () => onActionItemClick(
                                vehicle.isArchived ? ChangeStatus.ACTIVATE : ChangeStatus.DEACTIVATE
                            ),
                        },
                    ]}
                />
            </Grid>
            <Grid item xs={12} display={'flex'}>
                <Grid item xs={4} display="flex" marginRight="1rem">
                    <SmallVehicleImage
                        photo={vehicle?.pictures.find(el => el.isDefault)?.url
                        || vehicle?.pictures?.[0]?.url}
                        boxSize={'100px'}
                    />
                </Grid>
                <Grid item xs={8}>
                    <LabeledData
                        label={`${t('identifier')}:`}
                        data={vehicle?.identifier}
                        labelColor={COLORS.SLATE_GREY}
                        ignoreColumns
                        paddingBottom="1rem"
                        className={classes.mili}
                    />
                    <LabeledData
                        label={`${t('brand')}:`}
                        data={vehicle?.brand?.name}
                        labelColor={COLORS.SLATE_GREY}
                        ignoreColumns
                        paddingBottom="0"
                        className={classes.mili}
                    />
                    <LabeledData
                        label={`${t('model')}:`}
                        data={vehicle?.model?.name}
                        labelColor={COLORS.SLATE_GREY}
                        ignoreColumns
                        paddingBottom="0"
                        className={classes.mili}
                    />
                    <LabeledData
                        label={`${t('color')}:`}
                        data={vehicle?.color as string}
                        labelColor={COLORS.SLATE_GREY}
                        ignoreColumns
                        paddingBottom="0"
                        className={classes.mili}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VehicleCard;
