import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';

const UpdatePendingConfirmModal = ({
    onClose,
    onConfirm,
}: {
    onClose: () => void;
    onConfirm: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.updatePendingRide.title')}
            confirmButtonLabel={t('modals.updatePendingRide.leavePage')}
            cancelButtonLabel={t('buttonName.back')}
            maxWidth="sm"
        >
            <Typography>{t('modals.updatePendingRide.description')}</Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginTop: '1rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default UpdatePendingConfirmModal;
