import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { USE_DRIVER, USE_DRIVERS } from '../../../constants/queryKeys';
import { useDebounce } from '../../../hooks/useDebounce';
import apiClient from '../../../services/api';
import { DRIVERS, getDriverRoute, getDriversRoute, getToggleDriverStatusRoute } from '../../../services/routes';
import { AddDriverRequest, Driver, EditDriverRequest } from './types';

export const useDrivers = (search?: string) => {
    const debouncedSearchQuery = useDebounce(search, 500);

    const { data, isLoading, isError, isFetching, refetch }
     = useQuery([USE_DRIVERS, debouncedSearchQuery], () => getDrivers(debouncedSearchQuery || ''), {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         retry: false,
     });

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const useDriver = (id: number) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([USE_DRIVER], () => getDriver(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getDrivers = async (search: string): Promise<Driver[]> => {
    const { data } = await apiClient.get<{ data: Driver[] }>(getDriversRoute(search));

    return data.data;
};

const getDriver = async (id: number): Promise<Driver> => {
    const { data } = await apiClient.get<Driver>(getDriverRoute(id.toString()));

    return data;
};

export const editDriver = async (variables: EditDriverRequest): Promise<AxiosResponse> => {
    const { id, name, phoneNumber, pictureUrl } = variables;

    const response = await apiClient.put(getDriverRoute(id.toString()), {
        name,
        phoneNumber,
        pictureUrl,
    });

    return response;
};

export const addDriver = async (variables: AddDriverRequest): Promise<AxiosResponse> => {
    const { name, phoneNumber, pictureUrl } = variables;

    const response = await apiClient.post(DRIVERS, {
        name,
        phoneNumber,
        pictureUrl,
    });

    return response;
};

export const toggleDriverStatus = async (id: string, isDeactivated: boolean): Promise<AxiosResponse> => {
    const response = await apiClient.patch(getToggleDriverStatusRoute(id.toString()), { isDeactivated });

    return response;
};
