/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlightTakeoff, FlightLand } from '@mui/icons-material';
import { Grid, Box, Typography, Tooltip, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { FlightStatus } from '../helpers/flightStatusUtil';
import { FlightStatusDateTime } from './FlightStatusDateTime';

const useStyles = makeStyles({
    dateTimeLabel: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100% !important',
        marginRight: '2rem',
    },
    dateTimeLabelMobile: { paddingBottom: '1rem' },
    arrivalInfoMobile: { marginTop: '2rem' },
    airportIata: {
        fontWeight: 700,
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
    },
    flightInfoText: {
        color: COLORS.BLACK,
        fontSize: '1rem',
        lineHeight: '0.875rem',
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
});

interface AirportDetailsCardProps {
    airportType: 'departure' | 'arrival';
    airportData: any;
    isLargeScreen: boolean;
    status?: FlightStatus;
}

const AirportDetailsCard = ({ airportType, airportData, isLargeScreen, status }: AirportDetailsCardProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation();

    const icon = airportType === 'departure'
        ? <FlightTakeoff style={{ color: COLORS.SLATE_GREY }} />
        : <FlightLand style={{ color: COLORS.SLATE_GREY }} />;

    const dateLabel = dayjs(airportData?.times?.scheduled).format(DATE_FORMATS['ddd Do MMM']);

    return (
        <Grid
            item
            md={6}
            xs={12}
            className={clsx(
                classes.dateTimeLabel,
                isMobile && classes.dateTimeLabelMobile,
                !isLargeScreen && classes.arrivalInfoMobile
            )}
        >
            <Box display="flex" alignItems="center">
                {icon}
                <Tooltip title={airportData?.airportName} placement="top" arrow>
                    <Typography className={classes.airportIata} minWidth="7">
                        {airportData?.airportIata ?? ''} - {airportData?.airportCity ?? ''}
                    </Typography>
                </Tooltip>
                <Typography className={classes.flightInfoText} style={{ minWidth: '6.7rem' }}>
                    &#8226; {dateLabel}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" maxWidth="30rem">
                <Box>
                    <Typography className={classes.flightInfoText}>
                        {airportType === 'departure' ? 'Departure' : 'Arrival'}
                    </Typography>
                    <FlightStatusDateTime status={status} flightTimes={airportData?.times} />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                        <Typography className={classes.flightInfoText}>
                            {t('pages.singleRide.flightInformation.terminal')}
                        </Typography>
                        <Typography fontWeight="600">{airportData?.terminal || '-'}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" marginLeft="1rem">
                        <Typography className={classes.flightInfoText}>
                            {t('pages.singleRide.flightInformation.gate')}
                        </Typography>
                        <Typography fontWeight="600">{airportData?.gate || '-'}</Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default AirportDetailsCard;
