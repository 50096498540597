import { IconedData } from '@get-e/react-components';
import { AirlineSeatReclineExtra, Work } from '@mui/icons-material';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import theme from '../../../../styles/theme';
import { RideResponse } from '../../api/types';
import { Vehicle } from '../../components/updateRide/VehicleOptions';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles({
    image: {
        height: '62px',
        width: '182px',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
        marginBottom: 0,
    },
    cardContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    modal: {
        border: 'none',
        padding: '0',
        marginBottom: '1.5rem',
        marginTop: 0,
    },
    luggageSeatsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
    },
    vehicleInfo: {
        color: COLORS.BLACK,
        paddingBottom: '.6rem',
        fontWeight: 600,
    },
});

interface SelectedVehicleCardProps {
    data?: RideResponse;
    vehicle?: Vehicle;
    isInModal?: boolean;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const SelectedVehicleCard = ({
    data,
    vehicle,
    isInModal,
    renderChevrons,
    isExpanded,
}: SelectedVehicleCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container className={`${classes.cardContainer} ${isInModal ? classes.modal : ''}`}>
            <Grid className={classes.headingContainer}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.selectedVehicle.title')}
                </Typography>
                {!isInModal && renderChevrons}
            </Grid>
            {isExpanded && (
                <Grid
                    sx={{
                        padding: 0,
                        marginTop: isExpanded ? '1rem' : '0',
                    }}
                >
                    <Grid container flex="row" justifyContent="space-between">
                        <Grid item xs={6} lg={6} md={6}>
                            <Typography className={classes.vehicleInfo}>
                                {`${data?.vehicle?.class} ${data?.vehicle?.type}
                                 (${data?.vehicle?.brandAndModel})`}
                            </Typography>
                            <Box className={classes.luggageSeatsContainer}>
                                <IconedData
                                    dataTextBold
                                    icon={AirlineSeatReclineExtra}
                                    data={vehicle?.travellers || data?.vehicle?.seats}
                                    dataLabelColor={COLORS.BLACK}
                                    iconRightMargin="0"
                                    dataColor={COLORS.SLATE_GREY}
                                />
                                <IconedData
                                    dataTextBold
                                    icon={Work}
                                    data={vehicle?.luggages || data?.vehicle?.luggage}
                                    dataLabelColor={COLORS.BLACK}
                                    iconRightMargin="0.5rem"
                                    dataColor={COLORS.SLATE_GREY}
                                />

                            </Box>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                            <img
                                src={vehicle?.vehicleImage || data?.vehicle?.imageUrl}
                                alt="Vehicle"
                                className={classes.image}
                            />
                        </Grid>
                    </Grid>
                </Grid>)}
        </Grid>
    );
};

export default withExpandable(SelectedVehicleCard);
