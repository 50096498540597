/* eslint-disable newline-per-chained-call */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable complexity */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/quotes */

import { PrimaryButton, SecondaryButton } from "@get-e/react-components";
import { alpha } from "@material-ui/core/styles";
import { FormControlLabel, Grid, Link, Switch, Tabs, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { zonedTimeToUtc } from "date-fns-tz";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useState, SetStateAction, useEffect, MouseEvent, useMemo, useRef } from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router";

import { logAmplitudeEvent } from "../../../amplitude/amplitude";
import Notification from "../../../components/notification/Notification";
import { SINGLE_RIDE_PAGE } from "../../../constants/amplitude/commonKeys";
import {
  CANCEL_RIDE,
  CONFIRM_CANCELLATION_RIDE,
  CONFIRM_UPDATE,
  DONWLOAD_DOCUMENTATION,
  RIDE_INFORMATION_FLIGHT_TRACKING,
  SEND_CONFIRMATION,
  UPDATE_RIDE,
} from "../../../constants/amplitude/customerKeys";
import { COLORS } from "../../../constants/colors";
import { DATE_FORMATS } from "../../../constants/dateFormats";
import { getSingleRideRoute } from "../../../constants/urlPaths";
import { useCurrentProfileContext } from "../../../context/CurrentProfileContext";
import { Severity, useNotificationContext } from "../../../context/NotificationContext";
import { getDateTimeWithoutOffset } from "../../../helpers/getDateTimeWithoutOffset";
import { isElementPartialVisible } from "../../../helpers/isElementPartialVisible";
import { mapRideToSummaryRide } from "../../../helpers/maps/mapRideToSummaryRide";
import { RideStatus, getRideStatusKey } from "../../../helpers/rideStatusUtil";
import usePageLeave from "../../../hooks/usePageLeave";
import { PrimaryTimeZone } from "../../../services/types";
import theme from "../../../styles/theme";
import { FlightDetails, LocationType, Passenger } from "../../rides/api/types";
import StatusBadge from "../../rides/components/StatusBadge";
import downloadBookingConfirmation from "../../rides/customer/api/downloadBookingConfirmation";
import downloadReceipt from "../../rides/customer/api/downloadReceipt";
import { CustomFieldItem, RideAmplitudeEventProperties } from "../api/types";
import { useRide } from "../api/useRide";
import CancellationPoliciesCard from "../components/CancellationPoliciesCard";
import CancelRideConfirmModal from "../components/CancelRideConfirmModal";
import DriverInformation from "../components/DriverInformation";
import RideInformation, { Ride as RideType } from "../components/RideInformation";
import RideReview from "../components/RideReview";
import RideVehicleInformationCard from "../components/RideVehicleInformationCard";
import SendBookingConfirmationModal, { BookingConfirmationEmail } from "../components/SendBookingConfirmationModal";
import TravellerInformation, { Traveller } from "../components/travellerInformation/TravellerInformation";
import UpdatePendingConfirmModal from "../components/UpdatePendingConfirmModal";
import UpdateRideConfirmModal from "../components/UpdateRideConfirmModal";
import VehicleInformationCards, { Vehicle } from "../components/VehicleInformationCards";
import { cancelRide, updateFlightNumber, updateRideInformation, updateRideVehicle, updateTravellerInformation } from "./api";
import { useAvailabilites } from "./api/useAvailabilites";
import { useFlightSearch } from "./api/useFlightSearch";
import RideLoadingSkeleton from "./components/rideLoadingSkeleton/RideLoadingSkeleton";
import RideSummaryCard from "./components/RideSummaryCard";
import { RideContext, SummaryRideType } from "./context/RideContext";
import useCarInformation from "./hooks/useCarInformation";
import useRideInformation from "./hooks/useRideInformation";
import useTravellerInformation from "./hooks/useTravellerInformation";

const useStyles = makeStyles(styleTheme => ({
  mainWrapper: {
    alignItems: "center",
    padding: "0 2rem",
  },
  mainWrapperTopPadding: { paddingTop: "3.5rem" },
  header: {
    position: "sticky",
    top: "0",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    zIndex: 10,
    flexDirection: "column",
  },
  headerTitleWrapper: {
    marginBottom: "2rem",
    marginTop: "1rem",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    [styleTheme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "2rem",
    },
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "auto !important",
    [styleTheme.breakpoints.up("md")]: { marginBottom: 0 },
  },
  tabs: {
    paddingTop: "1rem 0",
    fontWeight: 700,
    borderBottom: `1px solid ${COLORS.SLATE_GREY}`,
    display: "flex",
    justifyContent: "space-between",
    [styleTheme.breakpoints.down("sm")]: { flexDirection: "column-reverse" },
  },
  utcSwitch: {
    whiteSpace: "nowrap",
    [styleTheme.breakpoints.down("sm")]: {
      display: "block",
      marginLeft: 0,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: COLORS.ORANGE_WARNING,
      "&:hover": { backgroundColor: alpha(COLORS.ORANGE_WARNING, theme.palette.action.hoverOpacity) },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { backgroundColor: COLORS.ORANGE_WARNING },
    "& .MuiFormControlLabel-label": { fontWeight: 700 },
  },
  utcSwitchChecked: { "& .MuiFormControlLabel-label": { color: COLORS.ORANGE_WARNING } },
  tab: {
    textDecoration: "none",
    color: COLORS.SLATE_GREY,
    padding: "1rem",
    "&:hover": { borderBottom: "none" },
  },
  activeTab: {
    borderBottom: `2px solid ${COLORS.BLUE}`,
    lineHeight: "1.3rem",
    color: `${COLORS.BLACK} !important`,
    "&:hover": { borderBottom: `2px solid ${COLORS.BLUE}` },
  },
  updateButton: { marginRight: "1rem" },
  buttons: { width: "150px" },
  heading: {
    marginBottom: "1rem",
    color: COLORS.BLUE_DARK,
    fontSize: "1rem",
    fontWeight: 700,
  },
  summary: {
    display: "flex",
    flexDirection: "column",
  },
  summaryReverse: {
    display: "flex",
    flexDirection: "column-reverse",
    marginBottom: "2rem",
  },
}));

enum ActiveElement {
  RIDE = "RIDE",
  CAR = "CAR",
  TRAVELLER = "TRAVELLER",
  POLICIES = "POLICIES",
  SUMMARY = "SUMMARY",
}

const REFETCH_VEHICLE_LIST_TIMEOUT_MS = 400;
let refetchVehicleListTimeoutId: NodeJS.Timeout | undefined;

const onPress = (event: MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  const targetId = event.currentTarget.getAttribute("href");
  const target = targetId && window.document.querySelector(targetId);

  if (target) {
    target.scrollIntoView({ behavior: "smooth" });
  }
};

const getLeadPassenger = (passengers: Passenger[]) => passengers.filter(passenger => passenger.isLead)[0];

const getTabLink = (tab: string) => `#${tab}`;
const HEADER_PADDING = 480;

export const CUSTOM_FIELD_KEY = "customField";

const driverInstructionsOnTop = [
  getRideStatusKey(RideStatus.CONFIRMED),
  getRideStatusKey(RideStatus.ACTIVE),
  getRideStatusKey(RideStatus.DRIVER_ON_THE_WAY),
  getRideStatusKey(RideStatus.DRIVER_AT_PICKUP),
  getRideStatusKey(RideStatus.PASSENGER_ON_BOARD),
];

const Ride = () => {
  const [isTypingFlightNumberFinished, setIsTypingFlightNumberFinished] = useState(false);
  const [isVehicleNotSelected, setIsVehicleNotSelected] = useState(false);
  const [isCheckingAvailabileVehicleList, setIsCheckingAvailabileVehicleList] = useState(false);
  const [isUpdateRideModalOpen, setIsUpdateRideModalOpen] = useState(false);
  const [isCancelRideModalOpen, setIsCancelRideModalOpen] = useState(false);
  const [isUpdatePendingModalOpen, setIsUpdatePendingModalOpen] = useState(false);
  const [isBackEnabled, setIsBackEnabled] = useState(false);
  const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
  const [activeElement, setActiveElement] = useState<keyof typeof ActiveElement>(ActiveElement.RIDE);
  const [isUpdateNotificationOpen, setIsUpdateNotificationOpen] = useState(false);
  const [isTravellerIncreasingDisabled, setIsTravellerIncreasingDisabled] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const { rideId }: { rideId: string } = useParams();
  const { showNotification } = useNotificationContext();
  const { data, isLoading, refetch } = useRide(rideId);
  const isFormDisabled = data?.status !== getRideStatusKey(RideStatus.BOOKED);
  const initialRideValues = useRef<RideType | null>(null);
  const initialVehicleValues = useRef<Vehicle | null>(null);
  const initialTravellerValues = useRef<Traveller[] | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { currentProfile: currentUser } = useCurrentProfileContext();
  const [isUtc, setIsUtc] = useState(currentUser.primaryTimeZone === PrimaryTimeZone.UTC);
  const whatIsChanged = useRef<Map<string, boolean | typeof CUSTOM_FIELD_KEY>>();
  const isPageOpened = useRef(false);

  const { rideValues, setRideValues, rideErrors, setRideErrors, validateRideFields } = useRideInformation(isUtc);

  const { travellerValues, setTravellerValues, travellerErrors, setTravellerErrors, validateTravellerFields } =
    useTravellerInformation();

  const { selectedVehicle, setSelectedVehicle } = useCarInformation();

  const { mutate: downloadBookingConfirmationMutation, isLoading: isDownloadingBookingConfirmation } =
    useMutation(downloadBookingConfirmation);

  const { mutate: downloadReceiptMutation, isLoading: isDownloadingReceipt } = useMutation(downloadReceipt);

  const { mutate: updateRideInformationMutation, isLoading: isUpdatingRideInformation } = useMutation(updateRideInformation, {
    onSuccess: () => {
      showNotification(t("alert.edit.success"), Severity.Info);
      refetch();
    },
    onError: (error: Error) => {
      showNotification(t("alert.edit.error"), Severity.Error);
    },
  });

  const { mutate: updateTravellerInformationMutation, isLoading: isUpdatingTravellers } = useMutation(
    updateTravellerInformation,
    {
      onSuccess: () => {
        showNotification(t("alert.edit.success"), Severity.Info);
        refetch();
      },
      onError: (error: Error) => {
        showNotification(t("alert.edit.error"), Severity.Error);
      },
    }
  );

  const { mutate: updateFlightNumberMutation, isLoading: isUpdatingFlightNumber } = useMutation(updateFlightNumber, {
    onSuccess: () => {
      showNotification(t("alert.edit.success"), Severity.Info);
      refetch();
    },
    onError: (error: Error) => {
      showNotification(t("alert.edit.error"), Severity.Error);
    },
  });

  const { mutate: cancelRideMutation, isLoading: isLoadingCancelRide } = useMutation(cancelRide, {
    onSuccess: () => {
      showNotification(t("pages.rides.successCancelRide"), Severity.Info);
      setIsCancelRideModalOpen(false);
      refetch();
      logAmplitudeEvent(CONFIRM_CANCELLATION_RIDE, rideAmplitudeEventProperties);
    },
    onError: (error: Error) => {
      showNotification(t("errors.cancelRide"), Severity.Error);
      setIsCancelRideModalOpen(false);
    },
  });

  const checkVehicleAfterRefetchAvailabilites = () => {
    if (selectedVehicle && isCheckingAvailabileVehicleList) {
      const { id, carId, ...restSelectedVehicle } = selectedVehicle;

      const vehicleExists = vehicleList.some(obj => {
        const { id: objId, ...restObj } = obj;

        if (JSON.stringify(restSelectedVehicle) === JSON.stringify(restObj)) {
          selectedVehicle.carId = objId;
          return true;
        }

        return false;
      });

      if (vehicleExists) {
        setSelectedVehicle(selectedVehicle);

        setIsCheckingAvailabileVehicleList(false);
      } else {
        setSelectedVehicle(null);
        setIsUpdateNotificationOpen(true);

        setIsCheckingAvailabileVehicleList(false);
      }
    }
  };

  const {
    vehicleList,
    newEstimateDistanceAndDuration,
    refetch: refetchAvailabileVehicleList,
    isLoading: isLoadingAvailabilites,
    isRefetching: isRefetchingAvailabilites,
    isFetched: areAvailabilitesFetched,
  } = useAvailabilites({
    pickup: {
      transferPoint: {
        id: rideValues.pickUpLocation?.id ?? "",
        type: rideValues.pickUpLocation?.type.toUpperCase() ?? "",
      },
      pickupTimeZulu: isUtc ? dayjs(rideValues.pickUpTime).format(DATE_FORMATS["YYYY-MM-DDTHH:mm:ss"]) : "",
      pickupTime: isUtc ? "" : dayjs(rideValues.pickUpTimeLocal).format(DATE_FORMATS["YYYY-MM-DDTHH:mm:ss"]),
    },
    dropOff: {
      transferPoint: {
        id: rideValues.dropOffLocation?.id ?? "",
        type: rideValues.dropOffLocation?.type.toUpperCase() ?? "",
      },
    },
    numberOfPassengers: parseInt(rideValues.travellers),
    numberOfBags: parseInt(rideValues.luggages),
  });

  useEffect(() => {
    if (areAvailabilitesFetched && isCheckingAvailabileVehicleList && !isRefetchingAvailabilites) {
      checkVehicleAfterRefetchAvailabilites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingAvailabileVehicleList, areAvailabilitesFetched, isRefetchingAvailabilites]);

  const isPickupDateDirty = () => {
    if (!initialRideValues.current) {
      return false;
    }

    if (isUtc) {
      return (
        initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
        initialRideValues.current.pickUpTime !== rideValues.pickUpTime
      );
    }

    return (
      initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
      initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal
    );
  };

  const isLocationDirty = () => {
    if (!initialRideValues.current) {
      return false;
    }

    return (
      initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name ||
      initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name
    );
  };

  // The logic for this calculation is described in the ticket TP-1336
  const searchFlightDate = useMemo(
    () => {
      let timeZone = "";
      let flightDate: Date | null = null;

      const rideDuration = newEstimateDistanceAndDuration?.durationInSeconds ?? 0;

      if (rideValues.pickUpLocation?.type === LocationType.AIRPORT) {
        timeZone = rideValues.pickUpLocation?.timeZone ?? "";
        flightDate = isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal;
      } else if (rideValues.dropOffLocation?.type === LocationType.AIRPORT) {
        timeZone = rideValues.dropOffLocation?.timeZone ?? "";

        if (isPickupDateDirty()) {
          flightDate = isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal;
          flightDate = dayjs(flightDate).add(rideDuration, "seconds").toDate();
        } else {
          flightDate = isUtc ? rideValues.dropOffDate : rideValues.dropOffDateLocal;
        }
      }

      if (isUtc && timeZone && flightDate) {
        return dayjs(zonedTimeToUtc(flightDate, timeZone)).format(DATE_FORMATS["YYYY-MM-DD"]);
      }

      return dayjs(flightDate).format(DATE_FORMATS["YYYY-MM-DD"]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUtc,
      rideValues.pickUpDate,
      rideValues.pickUpDateLocal,
      rideValues.dropOffDate,
      rideValues.dropOffDateLocal,
      rideValues.pickUpLocation?.timeZone,
      rideValues.pickUpLocation?.type,
      rideValues.dropOffLocation?.timeZone,
      rideValues.dropOffLocation?.type,
      newEstimateDistanceAndDuration?.durationInSeconds,
    ]
  );

  const {
    flightDetails: flightSearchDetails,
    refetch: refetchFlightDetails,
    isLoading: isLoadingFlight,
    isError: isErrorFlight,
  } = useFlightSearch(rideValues.flightNumber, searchFlightDate);

  const { mutate: updateVehicleMutation, isLoading: isUpdatingVehicle } = useMutation(updateRideVehicle, {
    onSuccess: () => {
      showNotification(t("alert.edit.successVehicleChange"), Severity.Info);
      refetch();
    },
    onError: (error: Error) => {
      showNotification(t("alert.edit.error"), Severity.Error);
    },
  });

  const rideAmplitudeEventProperties = useMemo(() => {
    return {
      "Ride number": data?.prettifiedUnid,
      "Ride created time": dayjs.utc(data?.createdAt).format(DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]),
      "Ride pickup time": dayjs.utc(data?.pickUp.departAt).format(DATE_FORMATS["YYYY-MM-DD HH:mm:ss"]),
      "Ride customer": data?.customer,
      "Ride supplier": data?.supplier,
      "Ride status": data?.status,
      "Ride situation tags": data?.situationTag,
      "Ride qc status": data?.qcStatus,
      "Ride has driver": Boolean(data?.driver),
    } as RideAmplitudeEventProperties;
  }, [
    data?.createdAt,
    data?.customer,
    data?.driver,
    data?.pickUp.departAt,
    data?.prettifiedUnid,
    data?.qcStatus,
    data?.situationTag,
    data?.status,
    data?.supplier,
  ]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }

    const leadPassenger = getLeadPassenger(data.passengers);
    const passengerFullName = leadPassenger ? `${leadPassenger.firstName} ${leadPassenger.lastName}` : "";

    const tagManagerArgs = {
      dataLayer: {
        name: currentUser?.fullName ?? null,
        email: currentUser?.email ?? null,
        accountType: currentUser?.accountType,
        rideNumber: data.unid,
        passengerName: passengerFullName,
      },
    };

    TagManager.dataLayer(tagManagerArgs);

    const newRideValues = {
      ...rideValues,
      flightNumber: data?.flightDetails?.number ?? "",
      flightDetails: data?.flightDetails ?? ({} as FlightDetails),
      id: data?.unid ?? "",
      pickUpDate: getDateTimeWithoutOffset(data?.pickUp?.departAt),
      pickUpTime: getDateTimeWithoutOffset(data?.pickUp?.departAt),
      pickUpDateLocal: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
      pickUpTimeLocal: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
      dropOffDate: getDateTimeWithoutOffset(data?.dropOff?.arriveAt),
      dropOffDateLocal: getDateTimeWithoutOffset(data?.dropOff?.arriveAtLocal),
      travellers: data?.numberOfPassengers?.toString() ?? "",
      luggages: data?.numberOfBags?.toString() ?? "",
      specialRequest: data?.specialRequest ?? "",
      customReference: data?.customerReference ?? "",
      customFields: data?.customFields ?? [],
      pickUpLocation: {
        id: data?.pickUp?.location?.id ?? "",
        name: data?.pickUp?.location?.name ?? "",
        address: data?.pickUp?.location?.address ?? "",
        type: data?.pickUp?.location?.type ?? "",
        latitude: data?.pickUp?.location?.coordinates?.lat ?? 0,
        longitude: data?.pickUp?.location?.coordinates?.lon ?? 0,
        timeZone: data?.pickUp?.departureTimeZone ?? "",
      },
      dropOffLocation: {
        id: data?.dropOff?.location?.id ?? "",
        name: data?.dropOff?.location?.name ?? "",
        address: data?.dropOff?.location?.address ?? "",
        type: data?.dropOff?.location?.type ?? "",
        latitude: data?.dropOff?.location?.coordinates?.lat ?? 0,
        longitude: data?.dropOff?.location?.coordinates?.lon ?? 0,
        timeZone: data?.dropOff?.arrivalTimeZone ?? "",
      },
      primaryTimeZone: currentUser?.primaryTimeZone ?? null,
      status: data?.status,
    } as RideType;

    setRideValues(newRideValues);

    const defaultTravellers: Traveller[] = data
      ? data.passengers.map(({ id, ...rest }) => ({
        id: id.toString(),
        ...rest,
      }))
      : [];

    setTravellerValues(defaultTravellers);

    const newVehicleValues = {
      id: data?.unid ?? "",
      vehicleClass: data?.vehicle.class,
      vehicleType: data?.vehicle?.type ?? "",
      vehicleImage: data?.vehicle?.imageUrl ?? "",
      travellers: data?.vehicle?.seats?.toString() ?? "",
      luggages: data?.vehicle?.luggage?.toString() ?? "",
      price: data?.price?.amount ?? 0,
      priceCurrency: data?.price?.isoCurrency ?? "",
    };

    setSelectedVehicle(newVehicleValues);

    const updatedErrorsValues = defaultTravellers.map(traveller => ({
      ...traveller,
      id: traveller.id,
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneError: null,
    }));

    setTravellerErrors(updatedErrorsValues);

    initialRideValues.current = newRideValues;
    initialVehicleValues.current = newVehicleValues;
    initialTravellerValues.current = defaultTravellers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    // Do not call flight search if pickup of dropoff location fields are dirty
    if (!isTypingFlightNumberFinished || isLocationDirty()) {
      return;
    }

    refetchFlightDetails();
    setIsTypingFlightNumberFinished(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypingFlightNumberFinished]);

  useEffect(() => {
    setRideValues({
      ...rideValues,
      flightDetails: isErrorFlight ? ({} as FlightDetails) : flightSearchDetails ?? ({} as FlightDetails),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightSearchDetails, isErrorFlight]);

  useEffect(() => {
    function checkVisibility() {
      const elements = isMobile
        ? document.querySelectorAll(".tab-elements")
        : document.querySelectorAll(`.tab-elements:not([id*="${ActiveElement.SUMMARY}"])`);

      const allTabs: Array<SetStateAction<string>> = [];

      elements.forEach(element => {
        const isVisible = isElementPartialVisible(element as HTMLInputElement, HEADER_PADDING);

        if (isVisible) {
          allTabs.push(element.id as keyof typeof ActiveElement);
        }
      });

      if (window.pageYOffset === 0) {
        setActiveElement(allTabs[0] as keyof typeof ActiveElement);
      } else {
        setActiveElement(allTabs[allTabs.length - 1] as keyof typeof ActiveElement);
      }
    }

    window.addEventListener("scroll", checkVisibility, { passive: true });

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, [isMobile]);

  useEffect(() => {
    if (selectedVehicle?.id && selectedVehicle?.id !== rideValues?.id) {
      const target = window.document.querySelector(getTabLink(ActiveElement.CAR));

      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle]);

  useEffect(() => {
    const isRideSetted = Boolean(rideValues.pickUpLocation?.id) && Boolean(rideValues.dropOffLocation?.id);

    const isSamePickupDate = isUtc
      ? initialRideValues.current?.pickUpDate === rideValues?.pickUpDate
      : initialRideValues.current?.pickUpDateLocal === rideValues?.pickUpDateLocal;

    const isSamePickupTime = isUtc
      ? initialRideValues.current?.pickUpTime === rideValues?.pickUpTime
      : initialRideValues.current?.pickUpTimeLocal === rideValues?.pickUpTimeLocal;

    const isInitialRideValues =
      initialRideValues.current?.pickUpLocation?.id === rideValues.pickUpLocation?.id &&
      initialRideValues.current?.dropOffLocation?.id === rideValues.dropOffLocation?.id &&
      isSamePickupDate &&
      isSamePickupTime;

    if (!isInitialRideValues && isRideSetted && validateRideFields()) {
      clearTimeout(refetchVehicleListTimeoutId);
      refetchVehicleListTimeoutId = setTimeout(() => {
        refetchAvailabileVehicleList();
        setIsCheckingAvailabileVehicleList(true);
      }, REFETCH_VEHICLE_LIST_TIMEOUT_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rideValues.pickUpLocation,
    rideValues.dropOffLocation,
    rideValues.pickUpDate,
    rideValues.pickUpTime,
    rideValues.pickUpDateLocal,
    rideValues.pickUpTimeLocal,
  ]);

  useEffect(() => {
    if (
      selectedVehicle === null ||
      rideValues.travellers !== data?.numberOfPassengers?.toString() ||
      rideValues.luggages !== data?.numberOfBags?.toString()
    ) {
      if (
        parseInt(rideValues.travellers) > parseInt(selectedVehicle?.travellers ?? "-1") ||
        parseInt(rideValues.luggages) > parseInt(selectedVehicle?.luggages ?? "-1")
      ) {
        clearTimeout(refetchVehicleListTimeoutId);
        refetchVehicleListTimeoutId = setTimeout(() => {
          refetchAvailabileVehicleList();
          setIsCheckingAvailabileVehicleList(true);
        }, REFETCH_VEHICLE_LIST_TIMEOUT_MS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rideValues.travellers, rideValues.luggages]);

  const areRideInformationValuesChanged = () => {
    const defaultValues = {
      travellers: data?.numberOfPassengers?.toString() ?? "",
      luggages: data?.numberOfBags?.toString() ?? "",
      specialRequest: data?.specialRequest ?? "",
      customReference: data?.customerReference ?? "",
      customFields: data?.customFields ?? "",
    };

    const currentValues = {
      travellers: rideValues.travellers,
      luggages: rideValues.luggages,
      specialRequest: rideValues.specialRequest,
      customReference: rideValues.customReference,
      customFields: rideValues.customFields,
    };

    return JSON.stringify(defaultValues) !== JSON.stringify(currentValues);
  };

  const areTravellerInformationValuesChanged = () => {
    const defaultPassengers = data?.passengers.map(({ id, ...keepAttrs }) => keepAttrs);
    const changedTravellerValues = travellerValues.map(({ id, ...keepAttrs }) => keepAttrs);

    return JSON.stringify(defaultPassengers) !== JSON.stringify(changedTravellerValues);
  };

  function handleUpdateRideInformation() {
    if (areRideInformationValuesChanged()) {
      const customFieldsPairs: CustomFieldItem[] = [];

      rideValues.customFields.forEach(customField => customFieldsPairs.push([customField.key, customField.value]));
      const customFields = customFieldsPairs.length > 0 ? Object.fromEntries(new Map(customFieldsPairs)) : null;

      updateRideInformationMutation({
        id: rideId,
        payload: {
          numberOfPassengers: parseInt(rideValues.travellers),
          numberOfBags: parseInt(rideValues.luggages),
          customerReference: rideValues.customReference,
          specialRequest: rideValues.specialRequest,
          customFields,
        },
      });
    }
  }

  function handleUpdateTravellerInformation() {
    if (areTravellerInformationValuesChanged()) {
      updateTravellerInformationMutation({
        id: rideId,
        payload: travellerValues.map(({ id, ...keepAttrs }) => keepAttrs),
      });
    }
  }

  function handleUpdateFlightNumber() {
    const defaultFlightNumber = data?.flightDetails?.number ?? "";
    const defaultPickUpDate = getDateTimeWithoutOffset(data?.pickUp?.departAtLocal ?? "");

    if (defaultFlightNumber !== rideValues.flightNumber && defaultPickUpDate !== rideValues.pickUpDate) {
      updateFlightNumberMutation({
        id: rideId,
        payload: {
          flightNumber: rideValues.flightNumber,
          departureDate: dayjs(isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal).format(DATE_FORMATS["YYYY-MM-DD"]),
        },
      });
    }
  }

  const onUpdateHandler = () => {
    const carId = selectedVehicle?.id === rideValues?.id ? selectedVehicle?.carId ?? "" : selectedVehicle?.id ?? "";

    // Update vehicle - swap estimate options
    if (selectedVehicle?.id) {
      updateVehicleMutation({
        rideId: rideValues.id,
        carId,
      });
    } else if (selectedVehicle === null) {
      setIsVehicleNotSelected(!isVehicleNotSelected);
      setIsUpdateRideModalOpen(false);
      return;
    }

    handleUpdateTravellerInformation();

    handleUpdateFlightNumber();
    handleUpdateRideInformation();

    setIsUpdateRideModalOpen(false);
    setIsCheckingAvailabileVehicleList(false);

    logAmplitudeEvent(CONFIRM_UPDATE, rideAmplitudeEventProperties);
  };

  const validateFields = () => validateTravellerFields() && validateRideFields();

  const handleRideUpdate = () => {
    if (validateFields()) {
      setIsUpdateRideModalOpen(true);

      logAmplitudeEvent(UPDATE_RIDE, rideAmplitudeEventProperties);
    }
  };

  const onCancelHandler = () => {
    cancelRideMutation(rideId);
  };

  const isActiveClass = (tab: string) => (ActiveElement[activeElement] === tab ? classes.activeTab : "");

  const handleFlightNumberFinishing = () => {
    setIsTypingFlightNumberFinished(true);

    if (initialRideValues?.current?.flightNumber !== rideValues.flightNumber) {
      logAmplitudeEvent(RIDE_INFORMATION_FLIGHT_TRACKING, rideAmplitudeEventProperties);
    }
  };

  const initialBookingConfirmationEmails: BookingConfirmationEmail[] = travellerValues.map(traveller => ({
    id: traveller.email,
    email: traveller.email,
    isIncluded: traveller.isLead,
  }));

  const isTravellerUpdated = () => {
    const initialTravellerKeys = initialTravellerValues.current?.map(
      traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
    );

    const currentTravellerKeys = travellerValues.map(
      traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
    );

    return !_.isEqual(initialTravellerKeys, currentTravellerKeys);
  };

  const isFormDirty = useMemo(() => {
    if (!initialRideValues.current) {
      return false;
    }

    if (
      initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name ||
      initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name ||
      initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
      initialRideValues.current.pickUpTime !== rideValues.pickUpTime ||
      initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
      initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal ||
      initialRideValues.current.travellers !== rideValues.travellers ||
      initialRideValues.current.luggages !== rideValues.luggages ||
      initialRideValues.current.flightNumber !== rideValues.flightNumber ||
      initialRideValues.current.specialRequest !== rideValues.specialRequest ||
      initialRideValues.current.customReference !== rideValues.customReference ||
      initialVehicleValues.current?.id !== selectedVehicle?.id ||
      isTravellerUpdated()
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rideValues, selectedVehicle, travellerValues]);

  const checkWhatIsChanging = () => {
    if (!initialRideValues.current) {
      return;
    }

    const changedCustomFields: string[] = [];

    initialRideValues.current.customFields.forEach(customField => {
      const currentCustomField = rideValues.customFields.filter(field => field.id === customField.id)[0];

      if (JSON.stringify(customField) !== JSON.stringify(currentCustomField)) {
        changedCustomFields.push(customField.label);
      }
    });

    const changes = new Map<string, boolean | typeof CUSTOM_FIELD_KEY>();

    changes.set("pickUpLocation", initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name);
    changes.set("dropOffLocation", initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name);
    changes.set("pickUpDate", initialRideValues.current.pickUpDate !== rideValues.pickUpDate);
    changes.set("pickUpTime", initialRideValues.current.pickUpTime !== rideValues.pickUpTime);
    changes.set("pickUpDateLocal", initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal);
    changes.set("pickUpTimeLocal", initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal);
    changes.set("travellers", initialRideValues.current.travellers !== rideValues.travellers);
    changes.set("luggages", initialRideValues.current.luggages !== rideValues.luggages);
    changes.set("flightNumber", initialRideValues.current.flightNumber !== rideValues.flightNumber);
    changes.set("specialRequest", initialRideValues.current.specialRequest !== rideValues.specialRequest);
    changes.set("customReference", initialRideValues.current.customReference !== rideValues.customReference);
    changes.set("vehicle", initialVehicleValues.current?.id !== selectedVehicle?.id);
    changes.set("passengerInformation", isTravellerUpdated());
    changedCustomFields.forEach(changedCustomField => {
      changes.set(changedCustomField, CUSTOM_FIELD_KEY);
    });
    whatIsChanged.current = changes;
  };

  const isUpdatedDisabled = useMemo(() => {
    if (!initialRideValues.current || !vehicleList || !selectedVehicle) {
      return true;
    }

    if (
      initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name ||
      initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name ||
      initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
      initialRideValues.current.pickUpTime !== rideValues.pickUpTime ||
      initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
      initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal ||
      initialRideValues.current.travellers !== rideValues.travellers ||
      initialRideValues.current.luggages !== rideValues.luggages ||
      initialRideValues.current.flightNumber !== rideValues.flightNumber ||
      initialRideValues.current.specialRequest !== rideValues.specialRequest ||
      initialRideValues.current.customReference !== rideValues.customReference ||
      JSON.stringify(initialRideValues.current.customFields) !== JSON.stringify(rideValues.customFields) ||
      initialVehicleValues.current?.id !== selectedVehicle?.id ||
      isTravellerUpdated()
    ) {
      checkWhatIsChanging();
      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rideValues, selectedVehicle, travellerValues]);

  const isUtcSwitchDisabled = useMemo(() => {
    if (!initialRideValues.current) {
      return true;
    }

    if (isUtc) {
      return (
        initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
        initialRideValues.current.pickUpTime !== rideValues.pickUpTime
      );
    }

    return (
      initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
      initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal
    );
  }, [isUtc, rideValues.pickUpDate, rideValues.pickUpDateLocal, rideValues.pickUpTime, rideValues.pickUpTimeLocal]);

  const handleSelectNewCar = (event: MouseEvent<HTMLAnchorElement>) => {
    onPress(event);
  };

  usePageLeave(
    isBackEnabled,
    getSingleRideRoute(rideId),
    setIsUpdatePendingModalOpen,
    isUpdatedDisabled,
    !isFormDirty || isFormDisabled
  );

  const vehicleUpdateNotification = useMemo(() => {
    if (vehicleList.length === 0 && !selectedVehicle) {
      return <Typography>{t("pages.singleRide.noVehicleAvailableExtended")}</Typography>;
    }

    return (
      <Typography>
        <Trans
          i18nKey="pages.singleRide.rideInformationChanged"
          components={{
            selectNewCar: (
              <Link
                sx={{
                  textDecoration: "underline",
                  color: COLORS.BLUE,
                  padding: "0 0.25rem",
                }}
                onClick={handleSelectNewCar}
                href={getTabLink(ActiveElement.CAR)}
              />
            ),
          }}
        />
      </Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle, vehicleList.length]);

  const showAvailabilityOptions = async () => {
    await refetchAvailabileVehicleList();
    setSelectedVehicle(null);
  };

  if (!data || isLoading) {
    return <RideLoadingSkeleton />;
  }

  if (!isPageOpened.current) {
    isPageOpened.current = true;
    logAmplitudeEvent(SINGLE_RIDE_PAGE, rideAmplitudeEventProperties);
  }

  return (
    <>
      <RideContext.Provider
        value={{
          summary: data ? mapRideToSummaryRide(data, rideValues, newEstimateDistanceAndDuration) : ({} as SummaryRideType),
          isDisabled: isFormDisabled,
          addedTravellerCount: travellerValues?.length ?? 0,
          isTravellerIncreasingDisabled,
          amplitudeEventProperties: rideAmplitudeEventProperties,
        }}
      >
        <Grid container className={clsx(classes.mainWrapper, { [classes.mainWrapperTopPadding]: isUpdateNotificationOpen })}>
          {isUpdateNotificationOpen && !(isLoadingAvailabilites || isRefetchingAvailabilites) && (
            <Notification
              isOpen={isUpdateNotificationOpen}
              content={vehicleUpdateNotification}
              onClose={() => setIsUpdateNotificationOpen(false)}
            />
          )}
          <Grid item xs className={classes.header}>
            <Grid item xs className={classes.headerTitleWrapper}>
              <Grid item className={clsx("tab-elements", classes.headerTitle)}>
                <Typography
                  style={{
                    color: COLORS.BLUE,
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    paddingRight: "1rem",
                  }}
                >
                  {t("ride")} {data?.prettifiedUnid}
                </Typography>
                <StatusBadge
                  status={data?.status}
                  trackingStatus={data?.trackingStatus}
                  pickupDateTimeUtc={data?.pickUp.departAt}
                />
              </Grid>
              <Grid item>
                {data.status === getRideStatusKey(RideStatus.BOOKED) && (
                  <PrimaryButton
                    onClick={handleRideUpdate}
                    className={clsx(classes.updateButton, classes.buttons)}
                    loading={
                      isLoading ||
                      isUpdatingRideInformation ||
                      isUpdatingVehicle ||
                      isUpdatingTravellers ||
                      isUpdatingFlightNumber
                    }
                    disabled={isUpdatedDisabled}
                  >
                    {t("pages.singleRide.update")}
                  </PrimaryButton>
                )}
                {data &&
                  data.status !== getRideStatusKey(RideStatus.TO_CLOSE) &&
                  data.status !== getRideStatusKey(RideStatus.COMPLETED) &&
                  data.status !== getRideStatusKey(RideStatus.CANCELLED) && (
                    <SecondaryButton
                      onClick={() => {
                        setIsCancelRideModalOpen(true);
                        logAmplitudeEvent(CANCEL_RIDE, rideAmplitudeEventProperties);
                      }}
                      className={classes.buttons}
                      variation="danger"
                    >
                      {t("pages.singleRide.cancelRide")}
                    </SecondaryButton>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.tabs}>
              <Tabs variant="scrollable" scrollButtons="auto" value={0}>
                {isMobile && (
                  <Link
                    onClick={onPress}
                    href={getTabLink(ActiveElement.SUMMARY)}
                    className={clsx(classes.tab, isActiveClass(ActiveElement.SUMMARY))}
                  >
                    {t("summary")}
                  </Link>
                )}
                <Link
                  onClick={onPress}
                  href={getTabLink(ActiveElement.RIDE)}
                  className={clsx(classes.tab, isActiveClass(ActiveElement.RIDE))}
                >
                  {t("rideInformation")}
                </Link>
                <Link
                  onClick={onPress}
                  href={getTabLink(ActiveElement.CAR)}
                  className={clsx(classes.tab, isActiveClass(ActiveElement.CAR))}
                >
                  {t("vehicleInformation")}
                </Link>
                <Link
                  onClick={onPress}
                  href={getTabLink(ActiveElement.TRAVELLER)}
                  className={clsx(classes.tab, isActiveClass(ActiveElement.TRAVELLER))}
                >
                  {t("passengerInformation")}
                </Link>
                <Link
                  onClick={onPress}
                  href={getTabLink(ActiveElement.POLICIES)}
                  className={clsx(classes.tab, isActiveClass(ActiveElement.POLICIES))}
                >
                  {t("pages.singleRide.policies")}
                </Link>
              </Tabs>
              <FormControlLabel
                className={clsx({
                  [classes.utcSwitch]: true,
                  [classes.utcSwitchChecked]: isUtc,
                })}
                control={
                  <Switch
                    checked={isUtc}
                    disabled={isUtcSwitchDisabled}
                    onChange={event => setIsUtc?.(event.target.checked)}
                    name="primaryTimeZone"
                  />
                }
                label={t("utcTime")}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "2rem" }} direction="row" columnSpacing={4}>
            <Grid item xs={12} md={12} lg={7.5} order={isMobile ? 2 : 1}>
              <Grid id={ActiveElement.RIDE} marginBottom="2.5rem" className="tab-elements">
                <RideInformation
                  rideValues={rideValues}
                  setRideValues={setRideValues}
                  errors={rideErrors}
                  setErrors={setRideErrors}
                  handleFlightNumberFinishing={handleFlightNumberFinishing}
                  isLoadingFlight={isLoadingFlight}
                  isErrorFlight={isErrorFlight}
                  isUtc={isUtc}
                  initialRideValues={initialRideValues.current}
                />
              </Grid>
              <Grid id={ActiveElement.CAR} marginBottom="2.5rem" marginTop="2rem" className="tab-elements">
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: COLORS.BLUE_DARK,
                    }}
                  >
                    {t("vehicleInformation")}
                  </Typography>
                </Box>
                <VehicleInformationCards
                  selectedVehicle={selectedVehicle}
                  setSelectedVehicle={setSelectedVehicle}
                  vehicleList={vehicleList}
                  isError={isVehicleNotSelected}
                  setIsError={() => setIsVehicleNotSelected(false)}
                  setIsUpdateNotificationOpen={setIsUpdateNotificationOpen}
                  showAvailabilityOptions={showAvailabilityOptions}
                  isLoading={isLoadingAvailabilites || isRefetchingAvailabilites}
                />
              </Grid>
              <Grid id={ActiveElement.TRAVELLER} marginBottom="2.5rem" className="tab-elements">
                <TravellerInformation
                  values={travellerValues}
                  setValues={setTravellerValues}
                  errors={travellerErrors}
                  setErrors={setTravellerErrors}
                  isDisabled={isFormDisabled}
                  setIsTravellerIncreasingDisabled={setIsTravellerIncreasingDisabled}
                />
              </Grid>
              <Grid id={ActiveElement.POLICIES} marginBottom={isMobile ? "1rem" : "2.5rem"} className="tab-elements">
                <CancellationPoliciesCard
                  isUtc={isUtc}
                  cancelledAt={data?.cancelledAt}
                  cancellationPolicies={data?.cancellationPolicies ?? []}
                  status={data?.status}
                  pickUpDate={rideValues.pickUpDate}
                  pickUpTime={rideValues.pickUpTime}
                  vehicleType={selectedVehicle?.vehicleType}
                  departureTimeZone={rideValues.pickUpLocation?.timeZone}
                  appliedCancellationPolicy={data?.appliedCancellationPolicy}
                />
              </Grid>
            </Grid>
            {data && (
              <Grid
                item
                id={ActiveElement.SUMMARY}
                xs={12}
                className="tab-elements"
                md={12}
                lg={4.5}
                order={isMobile ? 1 : 2}
                marginBottom={isMobile ? "2rem" : 0}
                display="flex"
                flexDirection="column"
              >
                <Box order={driverInstructionsOnTop.includes(data.status) ? 1 : 0}>
                  <RideSummaryCard
                    isDownloading={isDownloadingBookingConfirmation || isDownloadingReceipt}
                    sendBookingHandler={() => {
                      setIsSendBookingConfirmationModalOpen(true);

                      logAmplitudeEvent(SEND_CONFIRMATION, rideAmplitudeEventProperties);
                    }}
                    downloadBookingHandler={() => {
                      downloadBookingConfirmationMutation({
                        unid: data.unid,
                        prettifiedUnid: data.prettifiedUnid,
                      });

                      logAmplitudeEvent(DONWLOAD_DOCUMENTATION, rideAmplitudeEventProperties);
                    }}
                    downloadReceiptHandler={() =>
                      downloadReceiptMutation({
                        unid: data.unid,
                        prettifiedUnid: data.prettifiedUnid,
                      })
                    }
                    isUtc={isUtc}
                  />
                </Box>
                <Box order={driverInstructionsOnTop.includes(data.status) ? 2 : 1}>
                  <RideVehicleInformationCard
                    vehicleClass={data.vehicle.class}
                    vehicleType={data.vehicle.type}
                    vehicleImage={data.vehicle.imageUrl}
                    travellers={data.vehicle.seats}
                    luggages={data.vehicle.luggage}
                  />
                </Box>
                <Box order={driverInstructionsOnTop.includes(data.status) ? 0 : 2}>
                  <DriverInformation
                    rideStatus={data.status}
                    name={data.driver?.name}
                    phone={data.driver?.phoneNumber}
                    pickupDescription={data.pickupDescription}
                  />
                </Box>
                <Box order="3">
                  {data.status === getRideStatusKey(RideStatus.COMPLETED) && <RideReview feedbacks={data.feedbacks} />}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </RideContext.Provider>
      {isUpdateRideModalOpen && (
        <UpdateRideConfirmModal
          onClose={() => setIsUpdateRideModalOpen(false)}
          onConfirm={onUpdateHandler}
          changes={whatIsChanged.current}
          isLoading={isUpdatingRideInformation || isUpdatingVehicle || isUpdatingTravellers || isUpdatingFlightNumber}
          isSystemAutomatedRide={data?.isManagedByApi}
        />
      )}
      {isCancelRideModalOpen && (
        <CancelRideConfirmModal
          onClose={() => setIsCancelRideModalOpen(false)}
          onConfirm={onCancelHandler}
          cancellationPolicies={data.cancellationPolicies}
          selectedVehicle={selectedVehicle}
          isLoading={isLoadingCancelRide}
          isSystemAutomatedRide={data?.isManagedByApi}
        />
      )}
      {isUpdatePendingModalOpen && (
        <UpdatePendingConfirmModal
          onClose={() => setIsUpdatePendingModalOpen(false)}
          onConfirm={() => {
            setIsBackEnabled(true);
          }}
        />
      )}
      {isSendBookingConfirmationModalOpen && (
        <SendBookingConfirmationModal
          initialBookingConfirmationEmails={initialBookingConfirmationEmails}
          isOpen={isSendBookingConfirmationModalOpen}
          onClose={() => setIsSendBookingConfirmationModalOpen(false)}
          rideId={rideId}
        />
      )}
    </>
  );
};

export default Ride;
