/* eslint-disable @typescript-eslint/init-declarations */
import { Autocomplete, TextField, ModalAction as Modal } from '@get-e/react-components';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Box, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { MuiTelInput } from 'mui-tel-input';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { SINGLE_RIDE_DRIVER_SAVE } from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { getCountryCode } from '../../../../helpers/getCountryCode';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import useEffectAsync from '../../../../hooks/useEffectAsync';
import { AddDriverRequest, Driver } from '../../../drivers/api/types';
import { RideAmplitudeEventProperties, RideResponse } from '../../../ride/api/types';
import useSupplierDriverInformation, {
    ADD_DRIVER_ID,
    DEAFULT_ADD_DRIVER_BUTTON_OPTION,
    NO_RESULTS_DRIVER_ID,
    NO_RESULTS_DRIVER_OPTION,
} from '../../../ride/supplier/hooks/useSupplierDriverInformation';
import { assignDriverToRide } from '../api';

const useStyles = makeStyles({
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    mobileFieldDisabled: {
        '&:hover': { pointerEvents: 'none' },
        '& .MuiInputBase-root.MuiFilledInput-root': {
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.BLACK_12}`,
            borderRadius: 0,
            borderBottomStyle: 'hidden',
            '&.Mui-disabled:before': { borderBottom: `1px solid ${COLORS.BLACK_12}` },
            '&.MuiInputBase-input-MuiFilledInput-input.Mui-disabled': { marginLeft: '-1.5rem' },
        },
    },
    formDriverField: { marginBottom: '2.5rem' },
    noResults: {
        textAlign: 'center',
        padding: '.75rem 1rem',
    },
    addNewContainer: {
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
    },
    modalContent: { '& .MuiDialog-paper': { overflow: 'visible' } },
    borderBottom: { borderBottom: `1px solid ${COLORS.DARK_GRAY}` },
});

export interface AddDriverFields {
    name: string;
    mobile: string;
}

export interface AddDriverFormErrors {
    name: InputError | null;
    mobile: InputError | null;
}

interface AddDriverModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    rideId: string;
    onFinished: (updatedRide?: RideResponse) => void;
    amplitudeEventProperties?: RideAmplitudeEventProperties;
}

const AddDriverModalRidesOverview = ({
    isModalOpen,
    onClose,
    rideId,
    onFinished,
    amplitudeEventProperties,
}: AddDriverModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isDriverSelected, setIsDriverSelected] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [isNewDriverAdded, setIsNewDriverAdded] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [filterOptionsLength, setFilterOptionsLength] = useState(0);

    const [values, setValues] = useState<AddDriverFields>({
        name: '',
        mobile: '',
    });

    const [formErrors, setFormErrors] = useState<AddDriverFormErrors>({
        name: null,
        mobile: null,
    });

    const { showNotification } = useNotificationContext();

    const {
        driverInformation,
        driverInformations,
        addDriverInformation,
        setDriverInformation,
        driverInformationError,
        setDriverInformationError,
    } = useSupplierDriverInformation();

    const handleChange = <T extends keyof AddDriverFields>(key: T, newValue: AddDriverFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors({
            ...formErrors,
            [key]: null,
        });

        setDriverInformationError({
            ...driverInformationError,
            phoneNumberError: null,
        });
    };

    const validateFields = (): boolean => {
        const validatedName = isFilledString(!isDriverSelected ? values.name : driverInformation?.name ?? '', InputError.Empty);

        const validatedMobile = isFilledString(values.mobile, InputError.Empty);

        const fieldErros: AddDriverFormErrors = {
            name: validatedName.isValid ? null : validatedName.error,
            mobile: validatedMobile.isValid ? null : validatedMobile.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }

        setIsSubmited(true);

        if (isAdded) {
            const newDriver = {
                name: values.name,
                phoneNumber: values.mobile,
            } as AddDriverRequest;

            await addDriverInformation(newDriver);

            setIsNewDriverAdded(true);
        }
    };

    useEffectAsync(async () => {
        const handleAddNewDriver = isAdded ? isNewDriverAdded : true;
        let rideResponse: RideResponse | undefined;

        if (driverInformation?.id && handleAddNewDriver && isSubmited) {
            setIsSaving(true);
            await assignDriverToRide({
                driverId: driverInformation?.id?.toString(),
                rideId,
            }).then(({ data }) => {
                showNotification(t('alert.edit.success'), Severity.Info);
                logAmplitudeEvent(SINGLE_RIDE_DRIVER_SAVE, amplitudeEventProperties);
                rideResponse = data;
            })
                .catch(() => {
                    showNotification(t('alert.edit.error'), Severity.Error);
                })
                .finally(() => {
                    setIsSaving(false);
                    onClose();
                    onFinished(rideResponse);
                });
            setIsSubmited(false);
        }
    }, [isNewDriverAdded, driverInformation, rideId, isAdded, isSubmited]);

    const driverInformationOptions: Driver[] = useMemo(() => {
        const activeDrivers = driverInformations.filter(driverInformationItem => !driverInformationItem.isDeactivated);

        return isAdded ? [] : [DEAFULT_ADD_DRIVER_BUTTON_OPTION, ...activeDrivers];
    }, [driverInformations, isAdded]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            maxWidth="md"
            onSubmit={handleSubmit}
            title={t('pages.rides.addDriver')}
            confirmButtonLabel={t('buttonName.add')}
            cancelButtonLabel={t('buttonName.back')}
            modalContentClassName={classes.modalContent}
            isDisabled={isSaving}
        >
            {!isAdded
                ? (
                    <Autocomplete
                        label={t('pages.rides.nameOrTaxiVehicleNumber')}
                        options={driverInformationOptions}
                        getOptionLabel={(option: Driver) => `${option.name} (${option.phoneNumber})`}
                        filterOptions={options => {
                            if (options.length === 1) {
                                options.unshift(NO_RESULTS_DRIVER_OPTION);
                            }

                            const searchTerm = searchPhrase.toLowerCase().trim();

                            const filteredDrivers = options.filter(
                                driver => driver.name.toLowerCase().includes(searchTerm)
                                    || driver.phoneNumber.includes(searchTerm)
                            );

                            const found = filteredDrivers.some(el => el.id === ADD_DRIVER_ID);

                            if (!found) {
                                filteredDrivers.unshift(DEAFULT_ADD_DRIVER_BUTTON_OPTION);
                            }

                            setFilterOptionsLength(filteredDrivers.length);

                            return filteredDrivers;
                        }}
                        renderOption={(prop, option: Driver) => {
                            if (option.id === NO_RESULTS_DRIVER_ID) {
                                return (
                                    <Box key={option.id} component="li" {...prop} className={classes.noResults}>
                                        {t('noResultsFound')}
                                    </Box>
                                );
                            }

                            if (option.id === ADD_DRIVER_ID) {
                                return (
                                    <Box
                                        key={option.id}
                                        component="li"
                                        className={clsx(
                                            classes.addNewContainer,
                                            { [classes.borderBottom]: filterOptionsLength > 1 }
                                        )}
                                        onClick={() => {
                                            setIsDriverSelected(false);
                                            setIsAdded(true);
                                        }}
                                    >
                                        {`+ ${t('buttonName.addNew')}`}
                                    </Box>
                                );
                            }

                            const averageRate = option.feedbacks?.averageRate
                                ? option.feedbacks?.averageRate.toFixed(1).toString()
                                : '';

                            const count = option.feedbacks?.count ?? '';

                            return (
                                <Box
                                    key={option.id}
                                    component="li"
                                    {...prop}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        color: COLORS.SLATE_GREY,
                                        padding: '.75rem 1rem',
                                    }}
                                >
                                    <Typography marginRight="0.2rem" color={COLORS.BLACK}>
                                        {option.name} ({option.phoneNumber})
                                    </Typography>
                                    <Typography marginRight="0.2rem">{averageRate}</Typography>
                                    {count !== '' && count !== 0 && (
                                        <>
                                            <StarIcon
                                                style={{
                                                    fontSize: '1.25rem',
                                                    marginRight: '0.2rem',
                                                }}
                                            />
                                            <Typography>({option.feedbacks?.count})</Typography>
                                        </>
                                    )}
                                </Box>
                            );
                        }}
                        value={driverInformation}
                        onChange={(_, newValue) => {
                            setDriverInformation(newValue as Driver);
                            handleChange('mobile', newValue?.phoneNumber ?? '');
                            setIsDriverSelected(true);

                            setFormErrors({
                                ...formErrors,
                                name: null,
                                mobile: null,
                            });
                        }}
                        isError={formErrors.name !== null}
                        helperText={formErrors.name ? t(formErrors.name) : undefined}
                        required
                        className={clsx(classes.formField, classes.formDriverField)}
                        inputValue={searchPhrase}
                        onInputChange={(_, newInputValue) => {
                            setSearchPhrase(newInputValue);
                            handleChange('name', newInputValue);
                        }}
                    />
                )
                : (
                    <TextField
                        required={isAdded}
                        label={t('pages.rides.nameOrTaxiVehicleNumber')}
                        name="driverName"
                        value={searchPhrase}
                        onChange={event => {
                            setSearchPhrase(event.target.value);
                            handleChange('name', event.target.value);
                        }}
                        className={classes.formField}
                        error={formErrors.name !== null}
                        helperText={formErrors.name ? t(formErrors.name) : undefined}
                    />
                )}
            <MuiTelInput
                value={values.mobile}
                defaultCountry={getCountryCode()}
                label={t('form.fields.mobile')}
                className={clsx(classes.formField, { [classes.mobileFieldDisabled]: isDriverSelected })}
                name="mobile"
                onChange={value => handleChange('mobile', value)}
                error={formErrors?.mobile !== null || driverInformationError.phoneNumberError !== null}
                helperText={(formErrors?.mobile && t(formErrors.mobile)) || driverInformationError?.phoneNumberError}
                variant="filled"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PhoneOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
                disabled={isDriverSelected}
            />
        </Modal>
    );
};

export default AddDriverModalRidesOverview;
