/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import theme from '../../../styles/theme';
import { useRide } from '../api/useRide';
import RideHeader from './components/RideHeader';
import RideLoadingSkeleton from './components/rideLoadingSkeleton/RideLoadingSkeleton';
import { renderDesktopView, renderMobileView } from './helpers/renderRideView';

const useStyles = makeStyles(styleTheme => ({
    contentContainer: {
        paddingLeft: '2rem',
        marginBottom: '0.5rem',
        [styleTheme.breakpoints.down('lg')]: { padding: '0' },
    },
    cancellationPolicies: {
        marginTop: '1.5rem',
        width: '96%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        padding: '1rem',
        marginLeft: '2rem',
        position: 'relative',
        [styleTheme.breakpoints.down('lg')]: {
            marginLeft: '0',
            width: '100%',
        },
        '& h4': { color: `${COLORS.BLUE} !important` },
    },
    googleMapContainer: {
        height: '20rem',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1rem',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '.5rem',
        overflow: 'hidden',
        '& > *': {
            borderTopLeftRadius: '.5rem',
            borderTopRightRadius: '.5rem',
            overflow: 'hidden',
        },
    },
    googleMapContainerMobile: {
        height: '14.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1rem',
        marginBottom: '0.5rem',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '.5rem',
        '& > *': {
            borderTopLeftRadius: '.5rem',
            borderTopRightRadius: '.5rem',
            overflow: 'hidden',
        },
    },
    priceOnMap: {
        height: '62px',
        backgroundColor: COLORS.WHITE,
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1rem',
        justifyContent: 'flex-start',
        [styleTheme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            marginRight: '1rem',
        },
    },
}));

const RideView = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { rideId }: { rideId: string } = useParams();
    const { data, isLoading, refetch } = useRide(rideId);
    const { t } = useTranslation();

    if (isLoading) {
        return <RideLoadingSkeleton />;
    }

    return (
        <>
            <RideHeader data={data} isLoading={isLoading} refetch={refetch}/>
            {isMobile
                ? renderMobileView(data, classes, t, refetch)
                : renderDesktopView(data, classes, t, refetch)}
        </>
    );
};

export default RideView;
