import { DropdownMenu } from '@get-e/react-components';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import {
    Box,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { REPEATS } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { BlockedPeriodMessage } from '../../../helpers/repeats';
import { BlockedPeriod } from '../api/types';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const useStyles = makeStyles({
    tableRow: {
        height: '56px',
        cursor: 'pointer',
        transition: '150ms all ease-in-out',
    },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.WHITE,
    },
});

interface TableRowDesktopProps {
    repeats: REPEATS;
    blockedPeriod: BlockedPeriod;
    blockedPeriodMessage: BlockedPeriodMessage;
    handleEditBlockPeriod: (id: number) => void;
    handleDeleteBlockPeriod: (id: number) => Promise<void>;
}

const TableRowDesktop = ({
    repeats,
    blockedPeriod,
    blockedPeriodMessage,
    handleEditBlockPeriod,
    handleDeleteBlockPeriod,

}: TableRowDesktopProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen]
    = useState(false);

    const handleSubmitDelete = async (id: number) => {
        try {
            await handleDeleteBlockPeriod(id);
        } catch (error) {
            showNotification(t('errors.deleteBlockedPeriod'), Severity.Error);
        } finally {
            setIsConfirmDeleteModalOpen(false);
        }
    };

    return (
        <>
            <TableRow
                onClick={() => handleEditBlockPeriod(blockedPeriod.id)}
                key={blockedPeriod.id}
                className={classes.tableRow}
                hover
            >
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem' }}
                >
                    {blockedPeriod.description}
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
                    colSpan={2}
                >
                    <Box display="flex" alignItems="center">
                        <Box
                            display="flex"
                            alignItems="center"
                            margin="0 0.5rem 0 0"
                            color={COLORS.SLATE_GREY}
                        >
                            <Tooltip title={t('pages.availability.startsOn')} arrow>
                                <FlagOutlinedIcon />
                            </Tooltip>
                        </Box>
                        <Typography>{blockedPeriodMessage.part1} </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            margin="0 0.5rem 0 1.25rem"
                            color={COLORS.SLATE_GREY}
                        >
                            <Tooltip title={t('pages.availability.endsOn')} arrow>
                                <SportsScoreOutlinedIcon />
                            </Tooltip>
                        </Box>
                        <Typography>{blockedPeriodMessage.part2}</Typography>
                        {repeats !== REPEATS.DO_NOT_REPEAT && (
                            <Box
                                display="flex"
                                alignItems="center"
                                margin="0 0.5rem 0 1.25rem"
                                color={COLORS.SLATE_GREY}
                            >
                                <Tooltip title={t('pages.availability.repeatingRules')} arrow>
                                    <RepeatOutlinedIcon />
                                </Tooltip>
                            </Box>
                        )}
                        <Typography>{blockedPeriodMessage.part3}</Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            margin="0 0 0 auto"
                            color={COLORS.SLATE_GREY}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            <DropdownMenu
                                options={[
                                    {
                                        id: 1,
                                        label: t('buttonName.edit'),
                                        icon: EditOutlinedIcon,
                                        iconColor: COLORS.BLUE_DARK,
                                        labelColor: COLORS.BLUE_DARK,
                                        onClick: () =>
                                            handleEditBlockPeriod(blockedPeriod.id),
                                    },
                                    {
                                        id: 2,
                                        label: t('buttonName.delete'),
                                        icon: DeleteOutlineOutlinedIcon,
                                        iconColor: COLORS.BLUE_DARK,
                                        labelColor: COLORS.BLUE_DARK,
                                        onClick: () => setIsConfirmDeleteModalOpen(true),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
            {isConfirmDeleteModalOpen && (
                <ConfirmDeleteModal
                    isModalOpen={isConfirmDeleteModalOpen}
                    onClose={() => setIsConfirmDeleteModalOpen(false)}
                    repeats={repeats}
                    blockedPeriodMessage={blockedPeriodMessage}
                    submitDelete={() => handleSubmitDelete(blockedPeriod.id)}
                />
            )}
        </>
    );
};

export default TableRowDesktop;
