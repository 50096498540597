import { IconedData } from '@get-e/react-components';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import { OPERATIONS_EMAIL, UK_GLOBAL_OPERATIONS, WEB_WHATSAPP_REDIRECTING } from '../../constants/contacts';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';

const useStyles = makeStyles({
    phoneBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '.25rem',
    },
});

const handleOnMailClick = () => (window.location.href = `mailto:${OPERATIONS_EMAIL}`);

const ContactUsContact = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentProfile } = useCurrentProfileContext();

    return (
        <>
            <Box>
                <Box sx={{ padding: '.5rem 0' }}>
                    <IconedData
                        icon={EmailOutlinedIcon}
                        data={t('contact.emailAddress')}
                        dataLabel={`${t('navigation.supplier.support')}: `}
                        dataLabelColor={COLORS.SLATE_GREY}
                        isLinked
                        onClick={() => handleOnMailClick()}
                    />
                </Box>
                <Box className={classes.phoneBox} onClick={() => window.open(`${WEB_WHATSAPP_REDIRECTING}${UK_GLOBAL_OPERATIONS.replace(/\s/g, '')}`, '_blank')}>
                    <IconedData
                        icon={PhoneOutlinedIcon}
                        dataLabel={`${t('contact.ukGlobalOperations')}: `}
                        data={UK_GLOBAL_OPERATIONS}
                        dataLabelColor={COLORS.SLATE_GREY}
                        isLinked
                        bottomMargin="0"
                    />
                    <IconButton sx={{ color: COLORS.BLUE_DARK }}>
                        <WhatsAppIcon />
                    </IconButton>
                </Box>
            </Box>
            {currentProfile?.accountManager && (
                <Box sx={{ marginTop: '2rem' }}>
                    <Typography
                        sx={{
                            color: COLORS.SLATE_GREY,
                            fontWeight: '700',
                        }}
                    >
                        {t('modals.contact.accountManager')}: {currentProfile?.accountManager}
                        {currentProfile?.accountEmail
                            && (
                                <>
                                    <br />
                                    <Link
                                        href={`mailto:${currentProfile?.accountEmail}`}
                                        sx={{ textDecoration: 'underline' }}
                                        target="_blank"
                                    >
                                        {currentProfile?.accountEmail}
                                    </Link>
                                </>
                            )}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default ContactUsContact;
