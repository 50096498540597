import { Grid, Skeleton, Box, useMediaQuery, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    headerTitleWrapper: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: { marginBottom: '0.5rem' },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
            marginBottom: '0.5rem',
        },
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 263px)',
        marginTop: '2rem',
    },
}));

const UsersLoadingSkeleton = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Grid container className={classes.mainWrapper}>
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Skeleton variant="rectangular" width="200px" height={40} />
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        {!isMobile && <Skeleton variant="rectangular" width="150px" height={50} />}
                    </Box>
                </Grid>
            </Grid>

            <Grid item xs marginTop="2rem">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Skeleton variant="text" width={isMobile ? '30%' : '10%'} height={60} style={{ marginRight: '1rem' }} />
                    <Skeleton variant="text" width={isMobile ? '30%' : '10%'} height={60} style={{ marginRight: '1rem' }} />
                    <Skeleton variant="text" width={isMobile ? '30%' : '10%'} height={60} style={{ marginRight: '1rem' }} />
                </Box>
            </Grid>

            <Skeleton variant="text" width="100%" height={5} />

            {!isMobile
                ? (
                    <Box className={classes.content}>
                        <Skeleton variant="rectangular" width="100%" height="100%" />
                    </Box>
                )
                : (
                    <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                        {[...Array(3)].map((_, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton variant="rectangular" width="100%" height={50} />
                            </Grid>
                        ))}
                    </Grid>
                )}
        </Grid>
    );
};

export default UsersLoadingSkeleton;
