import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RemoveDriverConfirmModal = ({
    onClose,
    onConfirm,
    isLoading,
}: {
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
}) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={handleConfirm}
            title={t('modals.removeDriver.title')}
            confirmButtonLabel={t('buttonName.confirm')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="sm"
            isDisabled={isLoading}
        >
            <Typography
                style={{
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.removeDriver.description')}
            </Typography>
            <Typography>{t('modals.removeDriver.descriptionPart1')}
            </Typography>
        </Modal>
    );
};

export default RemoveDriverConfirmModal;
