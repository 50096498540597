import { TextField, MultipleSelect, ModalAction as Modal } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Checkbox, FormControlLabel, InputAdornment, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShowPasswordButton from '../../../components/buttons/showPasswordButton/ShowPasswordButton';
import { USER_ROLES } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { STATUS_422 } from '../../../constants/httpStatusCodes';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { InputError } from '../../../helpers/inputValidation/InputError';
import and from '../../../helpers/inputValidation/validators/and';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import isEqualString from '../../../helpers/inputValidation/validators/isEqualString';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import isPasswordStrong from '../../../helpers/inputValidation/validators/isPasswordStrong';
import { AccountType } from '../../../services/types';
import { editUser } from '../api';
import { EditUserRequest, ErrorResponse, User, EditUserPermissions, UserRoles } from '../api/types';
import userModalStyles from './UserModal.styles';

interface EditUserFields {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    permissions: EditUserPermissions;
    isActive: boolean;
}

interface EditUserErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
    password?: InputError | null;
    confirmPassword?: InputError | null;
}

interface EditUserModalProps {
    user: User;
    onEditUser: () => void;
    onClose: () => void;
    isOpen: boolean;
}

const EditUserModal = ({ user, onEditUser, onClose, isOpen }: EditUserModalProps) => {
    const classes = userModalStyles();
    const { t } = useTranslation();
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const { showNotification } = useNotificationContext();

    const [values, setValues] = useState<EditUserFields>({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: '',
        confirmPassword: '',
        permissions: {
            [UserRoles.MANAGE_USERS]: user.permissions.manageUsers,
            [UserRoles.MANAGE_RIDES_BOOKED_BY_OTHERS]: user.permissions.manageRidesBookedByOthers,
        },
        isActive: !user.isDeactivated,
    });

    const [formErrors, setFormErrors] = useState<EditUserErrors>({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
    });

    const roleMapValues = useMemo(() => {
        const roleMap = new Map([[USER_ROLES.MANAGE_USERS, `${t('pages.users.fields.manageUsers')}`]]);

        if (currentUser.accountType === AccountType.CUSTOMER) {
            roleMap.set(USER_ROLES.MANAGE_RIDES_BOOKED_BY_OTHERS, `${t('pages.users.fields.manageRidesBookedByOthers')}`);
        }

        return roleMap;
    }, [currentUser.accountType, t]);

    const defaultRoleValues = useMemo(() => {
        const result: string[] = [];

        for (const [key, value] of Object.entries(values.permissions)) {
            value && result.push(t(`pages.users.fields.${key}`));
        }

        return result;
    }, [t, values.permissions]);

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);

        const permissionValues = Array.from(roleMapValues.keys()).reduce(
            (acc, key) => Object.assign(acc, { [UserRoles[key as keyof typeof UserRoles]]: ids.includes(key) }),
            {}
        );

        const newValues = {
            ...values,
            permissions: permissionValues as EditUserPermissions,
        };

        setValues(newValues);
    };

    const validateFields = (): boolean => {
        const shouldValidatePassword = values.password.trim() !== '';

        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
            password: isPasswordStrong(values.password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(values.confirmPassword, values.password, InputError.NoMatch),
        };

        const fieldErros: EditUserErrors = {
            firstName: validated.firstName.isValid ? null : validated.firstName.error,
            lastName: validated.lastName.isValid ? null : validated.lastName.error,
            email: validated.email.isValid ? null : validated.email.error,
            password: validated.password.isValid || !shouldValidatePassword ? null : validated.password.error,
            confirmPassword: validated.confirmPassword.isValid || !shouldValidatePassword
                ? null
                : validated.confirmPassword.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = async (): Promise<void> => {
        if (!validateFields()) {
            return;
        }

        const shouldSubmitPassword = values.password.trim() !== '';

        const payload: EditUserRequest = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isDeactivated: !values.isActive,
            permissions: values.permissions,
        };

        if (shouldSubmitPassword) {
            payload.password = values.password;
            payload.password_confirmation = values.confirmPassword;
        }

        try {
            setIsSaving(true);
            await editUser(user.id, payload);
            onEditUser();
            onClose();
        } catch (error) {
            handleEditUserError(error as AxiosError);
        } finally {
            setIsSaving(false);
        }
    };

    const handleEditUserError = ({ response }: AxiosError) => {
        if (response?.status === STATUS_422) {
            const errorMessage = response?.data as ErrorResponse;

            if (errorMessage.message === t(InputError.EmailAlreadyTaken)) {
                const fieldErrors: EditUserErrors = {
                    firstName: null,
                    lastName: null,
                    email: InputError.EmailAlreadyTaken,
                    password: null,
                    confirmPassword: null,
                };

                setFormErrors(fieldErrors);
            }

            return;
        }

        showNotification(t('errors.defaultError'), Severity.Error);
    };

    const handleChange = <T extends keyof EditUserFields>(key: T, newValue: EditUserFields[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('pages.users.editUser')}
            confirmButtonLabel={t('buttonName.edit')}
            cancelButtonLabel={t('buttonName.cancel')}
            isDisabled={isSaving}
        >
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                label={t('form.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                label={t('form.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={formErrors.email !== null}
                helperText={formErrors.email ? t(formErrors.email) : null}
                label={t('email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {currentUser.id !== user.id && (
                <>
                    <TextField
                        label={t('form.fields.passwordFillToChange')}
                        type={showingPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={event => handleChange('password', event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    onShowPassword={password => {
                                        setShowingPassword(password);
                                    }}
                                />
                            ),
                            autoComplete: 'new-password',
                        }}
                        error={formErrors.password !== null}
                        helperText={formErrors.password ? t(formErrors.password) : null}
                        required
                        className={classes.formField}
                    />
                    <TextField
                        id="edit-user-confirm-password"
                        label={t('form.fields.confirmPassword')}
                        type={showingConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        onChange={event => handleChange('confirmPassword', event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    onShowPassword={password => {
                                        setShowingConfirmPassword(password);
                                    }}
                                />
                            ),
                            autoComplete: 'confirm-new-password',
                        }}
                        error={formErrors.confirmPassword !== null}
                        helperText={formErrors.confirmPassword ? t(formErrors.confirmPassword) : null}
                        required
                        className={classes.formField}
                    />
                </>
            )}
            {currentUser.id !== user.id && (
                <Box marginBottom="2.5rem">
                    <Typography
                        style={{
                            color: COLORS.BLUE_DARK,
                            fontSize: '1rem',
                            fontWeight: 700,
                            marginBottom: '1rem',
                        }}
                    >
                        {t('pages.users.fields.userPermissions')}
                    </Typography>
                    <MultipleSelect
                        label={t('pages.users.fields.role')}
                        value={roleIds}
                        values={roleMapValues}
                        onSetIds={onSetIds}
                        defaultSelectedValue={defaultRoleValues}
                        classNames={{ item: classes.multipleSelectItem }}
                        disabled={isSaving}
                    />
                </Box>
            )}
            {currentUser.id !== user.id && (
                <>
                    <Typography
                        style={{
                            color: COLORS.BLUE_DARK,
                            fontSize: '1rem',
                            fontWeight: 700,
                            marginBottom: '0.25rem',
                        }}
                    >
                        {t('pages.users.fields.userStatus')}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.isActive}
                                onChange={event => handleChange('isActive', event.target.checked)}
                            />}
                        label={t('active')}
                        sx={{
                            width: '100%',
                            marginRight: 0,
                        }}
                    />
                </>
            )}
        </Modal>
    );
};

export default EditUserModal;
