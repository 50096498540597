import dayjs from 'dayjs';

const minutesOf4Hours = 240;
const minutesOf24Hours = 1440;

export default function getDueInTime(startUtc: string | undefined, prefix: string) {
    if (!startUtc) {
        return '';
    }

    const startUtcMoment = dayjs.utc(startUtc);
    const nowUtcMoment = dayjs.utc();

    const durationDifference = dayjs.duration(startUtcMoment.diff(nowUtcMoment));
    const durationMinutes = durationDifference.asMinutes();

    const hours = (durationMinutes / 60);
    const roundHours = Math.floor(hours);
    const minutes = (hours - roundHours) * 60;
    const roundMinutes = Math.round(minutes);

    if (durationMinutes < 60) {
        return `${prefix} ${roundMinutes}m`;
    } else if (durationMinutes <= minutesOf4Hours) {
        return `${prefix} ${roundHours}h ${roundMinutes}m`;
    } else if (durationMinutes <= minutesOf24Hours) {
        return `${prefix} ${roundHours}h`;
    }

    return '';
}
