
import { IconedData, ModalAction as Modal } from '@get-e/react-components';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography, Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { CUSTOM_FIELD_KEY } from '../customer/Ride';

const useStyles = makeStyles({
    changesList: {
        marginTop: '0.5rem',
        paddingLeft: '1.5rem',
    },
    systemAutomatedBox: {
        padding: '.5rem',
        borderRadius: '.25rem',
        backgroundColor: COLORS.ORANGE_WARNING,
        marginBottom: '2rem',
    },
    contact: {
        textDecoration: 'underline',
        color: COLORS.WHITE,
        '&:hover': {
            color: COLORS.RED_WARNING,
            textDecoration: 'underline',
            borderBottom: 'none',
        },
    },
});

const UpdateRideConfirmModal = ({
    onClose,
    onConfirm,
    changes,
    isLoading,
    isSystemAutomatedRide,
}: {
    onClose: () => void;
    onConfirm: () => void;
    changes?: Map<string, boolean | typeof CUSTOM_FIELD_KEY>;
    isLoading?: boolean;
    isSystemAutomatedRide?: boolean;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { useManagedByCars } = useFlags();

    const changedItems = useMemo(() => {
        const items: string[] = [];

        const changedItemElements: JSX.Element[] = [];

        changes?.forEach((isChanged, field) => {
            isChanged && items.push(field);

            if (isChanged === true || isChanged === CUSTOM_FIELD_KEY) {
                changedItemElements.push(
                    <li key={field}>{typeof isChanged === 'boolean' ? t(`pages.singleRide.updatedFields.${field}`) : field}</li>
                );
            }
        });

        return (
            <ul className={classes.changesList}>
                {changedItemElements ?? (<></>)}
            </ul>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changes]);

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.updateRide.title')}
            confirmButtonLabel={t('buttonName.update')}
            cancelButtonLabel={t('buttonName.back')}
            maxWidth="sm"
            isDisabled={isLoading}
        >
            {isSystemAutomatedRide && useManagedByCars && (
                <Box className={classes.systemAutomatedBox}>
                    <IconedData
                        icon={WarningIcon}
                        data={t('modals.updateRide.isSystemAutomated2')}
                        dataLabel={t('modals.updateRide.isSystemAutomated1')}
                        dataLabelColor={COLORS.WHITE}
                        iconColor={COLORS.WHITE}
                        dataColor={COLORS.WHITE}
                        dataLabelBold
                    />
                    <Typography marginLeft="34px" color={COLORS.WHITE} fontWeight={700}>
                        {t('modals.updateRide.isSystemAutomated3')}
                        <Link
                            href={`mailto:${t('contact.emailAddress')}`}
                            className={classes.contact}
                        >
                            {t('modals.updateRide.isSystemAutomatedContact')}
                        </Link>
                        {t('modals.updateRide.isSystemAutomatedNext')}
                    </Typography>
                </Box>
            )}
            <Typography>{t('modals.updateRide.description')}</Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginTop: '1rem',
                }}
            >
                {t('modals.updateRide.whatIsChanging')}
            </Typography>
            {changedItems}
            <Typography
                style={{
                    fontWeight: 700,
                    marginTop: '1rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default UpdateRideConfirmModal;
