import { IconedData } from '@get-e/react-components';
import { Hail, ReceiptLong } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { Passenger, RideResponse } from '../../api/types';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles({
    passengerInfoContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
    },
    passenger: { marginTop: '1rem' },
});

interface PassengerInformationCardProps {
    data?: RideResponse;
    showClientInstructions?: boolean;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const PassengerInformationCard = ({
    data,
    showClientInstructions,
    renderChevrons,
    isExpanded,
}: PassengerInformationCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container className={classes.passengerInfoContainer}>
            <Grid className={classes.headingContainer}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.passengerInformation.title')}
                </Typography>
                {renderChevrons}
            </Grid>

            {isExpanded && <>
                {data?.passengers.map((passenger: Passenger, index: number) => (
                    <Grid
                        key={passenger.id}
                        className={index > 0 ? classes.passenger : undefined}
                        marginTop={isExpanded ? '1rem' : 0}
                    >
                        <IconedData
                            dataTextBold
                            icon={Hail}
                            data={`${passenger.firstName} ${passenger.lastName}`}
                            dataLabelColor={COLORS.BLACK}
                            isColumn
                            fontSize=".75rem"
                            fontTextSize="1rem"
                            dataLabelBold={false}
                            iconSize="1.25rem"
                        />
                        <Typography marginLeft="2rem" color={COLORS.BLACK}>
                            {passenger.phone}
                        </Typography>
                    </Grid>
                ))}
                {showClientInstructions && (
                    <IconedData
                        topMargin="1rem"
                        icon={ReceiptLong}
                        dataLabel={t('pages.singleRide.passengerInformation.clientInstructions')}
                        data={data?.pickupDescription}
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                        fontSize=".75rem"
                        fontTextSize="1rem"
                        dataLabelBold={false}
                        iconSize="1.25rem"
                    />
                )}
            </>}
        </Grid>
    );
};

export default withExpandable(PassengerInformationCard);
