/* eslint-disable complexity */
import { SecondaryButton, IconedData } from '@get-e/react-components';
import {
    Badge,
    Phone,
    ReceiptLong,
    Star,
    Crop169,
    ColorLens,
    Bookmark,
    StarsOutlined,
    Face,
    DirectionsCar,
} from '@mui/icons-material';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { COLORS } from '../../../../constants/colors';
import ReferencesAndRemarksModal from '../../../rides/supplier/components/ReferencesAndRemarksModal';
import { useRideVehicle } from '../../../vehicles/api';
import { Photo } from '../../../vehicles/api/types';
import { ColorOption } from '../../../vehicles/colorOptions';
import ColorCircle from '../../../vehicles/colorOptions/ColorCircle';
import { RideResponse } from '../../api/types';
import withExpandable from '../../components/withExpandable';
import DriverVehicleModal from './DriverVehicleModal';

const useStyles = makeStyles({
    driverInformationContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
    },
    button: {
        marginRight: '1rem',
        border: `1px solid ${COLORS.BLUE}`,
        color: COLORS.BLUE,
        width: '105px !important',
        fontSize: '13px',
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    manageButton: {
        border: `1px solid ${COLORS.BLUE}`,
        color: COLORS.BLUE,
        width: '105px !important',
        fontSize: '13px !important',
        height: '37px !important',
    },
    image: {
        width: '85px',
        height: '80px',
        borderRadius: '4px',
        marginTop: '1.3rem',
    },
    informationContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    entityImage: {
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '4px',
        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
        width: '85px',
        minWidth: '85px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80px',
        marginTop: '1.3rem',
        '& .MuiSvgIcon-root': {
            fontSize: '2.3rem',
            color: COLORS.DARK_GRAY,
        },
    },
});

interface DriverReferencesCardProps {
    data?: RideResponse;
    refetch?: () => void;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
    isCustomerPage?: boolean;
}

const DriverReferencesCard
= ({ data, refetch, renderChevrons, isExpanded, isCustomerPage }: DriverReferencesCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { rideId }: { rideId: string } = useParams();
    const isLargeScreen = useMediaQuery('(max-width:1470px)');
    const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
    const [isDriverVehicleModalOpen, setIsDriverVehicleModalOpen] = useState(false);
    const { data: vehicle, refetch: refetchVehicle } = useRideVehicle(rideId);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refetchVehicle();
    }, [data, refetchVehicle]);

    return (
        <Grid container className={classes.driverInformationContainer}>
            <Box marginBottom={isExpanded && !isCustomerPage ? '1rem' : 0}>
                <Grid className={classes.headingContainer}>
                    <Typography variant="h3" component="h4" className={classes.heading}>
                        {t('pages.singleRide.driverInformation.title')}
                    </Typography>
                    {renderChevrons}
                </Grid>
                {isExpanded && (
                    <>
                        <Box className={classes.informationContainer}>
                            {data?.driver?.pictureUrl
                                ? (
                                    <img
                                        className={classes.image}
                                        src={data?.driver?.pictureUrl || ''}
                                        alt="vehicle"
                                    />
                                )
                                : <Grid className={classes.entityImage}><Face /></Grid>}
                            <Box marginLeft="1rem">
                                <Box display="flex" alignItems="center" marginBottom="0.25rem" marginTop="1rem">
                                    <Box display="flex" flexWrap="wrap" alignItems="center">
                                        <IconedData
                                            dataTextBold
                                            icon={Badge}
                                            dataLabel={t('pages.singleRide.driverInformation.driver')}
                                            data={data?.driver?.name || '-'}
                                            dataLabelColor={COLORS.BLACK}
                                            isColumn
                                            bottomMargin="1rem"
                                            fontSize=".75rem"
                                            fontTextSize="1rem"
                                            dataLabelBold={false}
                                            iconSize="1.25rem"
                                        />
                                        {Boolean(data?.driver?.feedbacks) && (
                                            <Box display="flex" alignItems="center">
                                                <Typography color={COLORS.SLATE_GREY}>
                                                    {(data?.driver?.feedbacks?.averageRate ?? 0).toFixed(1)}
                                                </Typography>
                                                <Star
                                                    style={{
                                                        color: COLORS.SLATE_GREY,
                                                        fontSize: '1rem',
                                                        padding: '0 0.25rem',
                                                    }}
                                                />
                                                <Typography color={COLORS.SLATE_GREY}>
                                                    {`(${data?.driver?.feedbacks?.count ?? 0} ${t('reviews').toLowerCase()})`}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    <IconedData
                                        dataTextBold
                                        icon={Phone}
                                        dataLabel={t('pages.singleRide.driverInformation.phone')}
                                        data={data?.driver?.phoneNumber || '-'}
                                        dataLabelColor={COLORS.BLACK}
                                        isColumn
                                        fontSize=".75rem"
                                        fontTextSize="1rem"
                                        dataLabelBold={false}
                                        iconSize="1.25rem"
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Grid maxWidth="85%" marginTop="1rem">
                            <Typography variant="h3" component="h4" className={classes.heading}>
                                {t('vehicleInformation')}
                            </Typography>
                            <Box className={classes.informationContainer}>
                                {vehicle?.pictures && vehicle?.pictures?.length > 0
                                    ? (
                                        <img
                                            className={classes.image}
                                            src={vehicle?.pictures?.find((pic: Photo) => pic.isDefault)?.url
                                             || vehicle?.pictures[0].url}
                                            alt="vehicle"
                                        />
                                    )
                                    : <Grid className={classes.entityImage}><DirectionsCar /></Grid>}
                                <Box marginLeft="1rem">
                                    <Box
                                        marginTop="1rem"
                                        display="flex"
                                        flexDirection={isLargeScreen ? 'column' : 'row'}
                                        justifyContent="space-between"
                                        alignItems={isLargeScreen ? 'flex-start' : 'center'}
                                    >
                                        <IconedData
                                            minWidth="157px"
                                            dataTextBold
                                            icon={Crop169}
                                            dataLabel={t('licensePlate')}
                                            data={vehicle?.licensePlate || '-'}
                                            dataLabelColor={COLORS.BLACK}
                                            isColumn
                                            iconRightMargin="0.625rem"
                                            bottomMargin="1rem"
                                            fontSize=".75rem"
                                            fontTextSize="1rem"
                                            dataLabelBold={false}
                                            iconSize="1.25rem"
                                        />
                                        <IconedData
                                            minWidth="157px"
                                            dataTextBold
                                            icon={StarsOutlined}
                                            dataLabel={t('brand&model')}
                                            data={
                                                vehicle
                                                    ? (vehicle.brand?.name || '')
                                                     + (vehicle.brand?.name && vehicle.model?.name ? ' ' : '')
                                                      + (vehicle.model?.name || '') || '-'
                                                    : '-'
                                            }
                                            dataLabelColor={COLORS.BLACK}
                                            isColumn
                                            bottomMargin="1rem"
                                            fontSize=".75rem"
                                            fontTextSize="1rem"
                                            dataLabelBold={false}
                                            iconSize="1.25rem"
                                        />
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection={isLargeScreen ? 'column' : 'row'}
                                        justifyContent="space-between"
                                        alignItems={isLargeScreen ? 'flex-start' : 'center'}
                                    >
                                        <IconedData
                                            minWidth="157px"
                                            dataTextBold
                                            icon={ColorLens}
                                            dataLabel={t('color')}
                                            data={
                                                vehicle && vehicle?.color
                                                    ? (
                                                        <ColorCircle
                                                            option={vehicle?.color as ColorOption}
                                                            paddingBox="0"
                                                            textBold
                                                        />
                                                    )
                                                    : '-'
                                            }
                                            dataLabelColor={COLORS.BLACK}
                                            isColumn
                                            bottomMargin="1rem"

                                            fontSize=".75rem"
                                            fontTextSize="1rem"
                                            dataLabelBold={false}
                                            iconSize="1.25rem"
                                        />
                                        {!isCustomerPage && (
                                            <IconedData
                                                minWidth="157px"
                                                dataTextBold
                                                icon={Bookmark}
                                                dataLabel={t('identifier')}
                                                data={vehicle?.identifier || '-'}
                                                dataLabelColor={COLORS.BLACK}
                                                isColumn
                                                fontSize=".75rem"
                                                fontTextSize="1rem"
                                                dataLabelBold={false}
                                                iconSize="1.25rem"
                                            />)}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {!isCustomerPage && (
                            <Grid item xs={12} marginTop="1rem">
                                <SecondaryButton
                                    onClick={() => {
                                        setIsDriverVehicleModalOpen(true);
                                    }}
                                    className={classes.button}
                                >
                                    {t('buttonName.manage')}
                                </SecondaryButton>
                            </Grid>
                        )}
                    </>
                )}
            </Box>

            {isExpanded && !isCustomerPage && <>
                <Grid>
                    <Grid>
                        <Typography variant="h3" component="h4" className={classes.heading}>
                            {t('pages.singleRide.referenceAndRemarks')}
                        </Typography>
                    </Grid>

                    <IconedData
                        topMargin="1rem"
                        dataTextBold
                        icon={ReceiptLong}
                        dataLabel={t('pages.singleRide.references.reference')}
                        data={data?.supplierReference || '-'}
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                        fontSize=".75rem"
                        fontTextSize="1rem"
                        dataLabelBold={false}
                        iconSize="1.25rem"
                    />

                    {data?.customFields?.map(field => (
                        <IconedData
                            topMargin="0.5rem"
                            key={field.id}
                            dataTextBold
                            icon={ReceiptLong}
                            dataLabel={field.label}
                            data={field.value || '-'}
                            dataLabelColor={COLORS.BLACK}
                            isColumn
                            fontSize=".75rem"
                            fontTextSize="1rem"
                            dataLabelBold={false}
                            iconSize="1.25rem"
                        />
                    ))}
                    <SecondaryButton
                        onClick={() => {
                            setIsRemarksModalOpen(true);
                        }}
                        className={classes.button}
                    >
                        {t('buttonName.update')}
                    </SecondaryButton>
                </Grid>
                {isRemarksModalOpen && (
                    <ReferencesAndRemarksModal
                        rideId={rideId}
                        isModalOpen={isRemarksModalOpen}
                        onClose={() => setIsRemarksModalOpen(false)}
                        onFinished={() => {
                            setIsRemarksModalOpen(false);
                            refetch?.();
                        }}
                        supplierReference={data?.supplierReference || undefined}
                    />
                )}
                {isDriverVehicleModalOpen && (
                    <DriverVehicleModal
                        rideStatus={data?.status}
                        initialVehicle={vehicle || null}
                        initialDriver={data?.driver || null}
                        isModalOpen={isDriverVehicleModalOpen}
                        onClose={() => {
                            refetch?.();
                            setIsDriverVehicleModalOpen(false);
                        }}
                    />
                )}
            </>}

        </Grid>
    );
};

export default withExpandable(DriverReferencesCard);
