/* eslint-disable @typescript-eslint/indent */
import { IconedData } from '@get-e/react-components';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Typography, Box, Grid, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../../constants/colors';
import theme from '../../../../styles/theme';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem .5rem .5rem .5rem',
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
        background: COLORS.EXTRA_LIGHT_GRAY,
    },
    title: {
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
    ratingBox: {
        display: 'flex',
        alignItems: 'center',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '.5rem',
    },
});

export const useSituationsDetailsContent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(
        () =>
            ({ row }: GridRowParams) =>
            (
                <Box className={classes.container}>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {isMobile && (
                            <>
                                <Grid item xs={12} display="flex">
                                    <IconedData
                                        icon={PersonOutlineOutlinedIcon}
                                        data={row.driver}
                                        dataColor={COLORS.BLACK}
                                    />
                                </Grid>
                                <Grid item xs={12} display="flex">
                                    <Typography fontWeight="700">{t('rideNumber')}:&nbsp;</Typography>
                                    <Link to={'/'}>
                                        <Typography color={COLORS.BLUE}>{row.rideNumber}</Typography>
                                    </Link>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            ),
        [classes.container, t, isMobile]
    );
};
