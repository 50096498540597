import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { updateUserStatus } from '../api';
import { User } from '../api/types';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
});

interface ChangeUserStatusModalProps {
    user: User;
    isActive: boolean;
    onClose: () => void;
    onUpdateUserStatus: () => void;
}

const ChangeUserStatusModal = ({ user, isActive, onClose, onUpdateUserStatus }: ChangeUserStatusModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { showNotification } = useNotificationContext();

    const handleUpdateStatus = async () => {
        try {
            await updateUserStatus(user.id, !isActive);
            onUpdateUserStatus();
        } catch (error) {
            showNotification(t('errors.defaultError'), Severity.Error);
        } finally {
            onClose();
        }
    };

    const statusNamespace = isActive ? 'reactivate' : 'deactivate';

    const confirmButtonLabel = isActive ? t('buttonName.activate') : t('buttonName.deactivate');

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={handleUpdateStatus}
            title={t(`modals.${statusNamespace}.title`)}
            cancelButtonLabel= {t('buttonName.cancel')}
            confirmButtonLabel={confirmButtonLabel}
            confirmButtonClassName={classes.confirmButton}
            maxWidth="sm"
        >
            <Typography fontWeight="700">
                {t(`modals.${statusNamespace}.description`)}
            </Typography>
            <Typography
                style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                }}
            >
                {t(`modals.${statusNamespace}.descriptionDetailed`)}
            </Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginBottom: '2rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default ChangeUserStatusModal;
