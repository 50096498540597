import { TextField, ModalAction as Modal } from '@get-e/react-components';
import { PhoneOutlined, Face, Delete } from '@mui/icons-material';
import { Box, InputAdornment, Grid, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    ADD_OR_EDIT_DRIVER_NAME_SURNAME_INPUT,
    ADD_OR_EDIT_DRIVER_PHONE_NUMBER_INPUT,
    ADD_OR_EDIT_DRIVER_PHOTO_INPUT,
    ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT,
    ADD_OR_EDIT_DRIVER_SAVE,
} from '../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../constants/colors';
import { getCountryCode } from '../../../helpers/getCountryCode';
import { InputError } from '../../../helpers/inputValidation/InputError';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import { ImageUrl } from '../../vehicles/api/types';
import { useUploadedImageUrls } from '../../vehicles/api/useUploadedImageUrls';
import ImageUploader from '../../vehicles/components/imageUploader/ImageUploader';
import { Driver, EditDriverRequest } from '../api/types';

const useStyles = makeStyles(styleTheme => ({
    editDriverModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '600px' } },
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        [styleTheme.breakpoints.down('md')]: { flexDirection: 'column' },
    },
    imageUploaderContainer: {
        marginTop: '-55px',
        [styleTheme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: '3rem',
        },
    },
    photoUploadContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        textAlign: 'center',
        width: '200px',
        cursor: 'pointer',
    },
    faceIcon: {
        color: COLORS.SLATE_GREY,
        fontSize: '40px',
    },
    photoUploadDescription: {
        fontSize: '12px',
        color: 'gray',
        '& span': {
            textDecoration: 'underline',
            display: 'block',
            fontWeight: 700,
        },
    },
    imageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '154px',
        height: '154px',
        borderRadius: '8px',
        border: `1px solid ${COLORS.DARK_GRAY}`,
        backgroundColor: COLORS.GREY_INPUT,
        marginTop: '15px',
        [styleTheme.breakpoints.down('md')]: { marginTop: 0 },
    },
    deleteIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: COLORS.WHITE,
    },
}));

interface DriverFields {
    name: string;
    mobile: string;
}

interface DriverFormErrors {
    name: InputError | null;
    mobile: InputError | null;
}

interface EditDriverModalProps {
    driver: Driver;
    isModalOpen: boolean;
    onClose: () => void;
    onEditDriver: (driverInformation: EditDriverRequest) => void;
    isSaving?: boolean;
}

const EditDriverModal = ({ driver, isModalOpen, onClose, onEditDriver, isSaving }: EditDriverModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { useDriverProfilePicture } = useFlags();

    const [files, setFiles] = useState<string[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);

    const [values, setValues] = useState<DriverFields>({
        name: driver.name,
        mobile: driver.phoneNumber,
    });

    const [formErrors, setFormErrors] = useState<DriverFormErrors>({
        name: null,
        mobile: null,
    });

    const handleChange = <T extends keyof DriverFields>(key: T, newValue: DriverFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
    };

    const validateFields = (): boolean => {
        const validatedName = isFilledString(values.name, InputError.Empty);
        const validatedMobile = isFilledString(values.mobile, InputError.Empty);

        const fieldErros: DriverFormErrors = {
            name: validatedName.isValid ? null : validatedName.error,
            mobile: validatedMobile.isValid ? null : validatedMobile.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        if (useDriverProfilePicture) {
            setIsSubmitted(true);
        } else {
            const updatedDriver = {
                id: driver.id,
                name: values.name,
                phoneNumber: values.mobile,
            } as EditDriverRequest;

            onEditDriver(updatedDriver);
            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_SAVE);
        }
    };

    const resultsOfImages = useUploadedImageUrls(isSubmitted, files);

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }

        const allSettled = resultsOfImages.every(result => result.isSuccess || result.isError);

        if (allSettled) {
            const successfulUploads = resultsOfImages
                .filter(result => result.isSuccess)
                .map(result => result.data) as ImageUrl[];

            const updatedDriver = {
                id: driver.id,
                name: values.name,
                phoneNumber: values.mobile,
                pictureUrl: (deleteClicked && files.length === 0) ? '' : successfulUploads[0]?.url,
            } as EditDriverRequest;

            onEditDriver(updatedDriver);
            setIsSubmitted(false);
            setDeleteClicked(false);
            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_SAVE);
        }

        setIsLoadingImages(resultsOfImages.some(result => result.isLoading || result.isFetching));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsOfImages, isSubmitted, values, onEditDriver]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('pages.drivers.editDriver')}
            confirmButtonLabel={t('buttonName.edit')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            modalContentClassName={classes.editDriverModal}
        >
            <Grid className={classes.contentContainer}>
                {useDriverProfilePicture && (
                    <Grid className={classes.imageUploaderContainer} marginRight={!files.length ? '16px' : 0}>
                        {driver.pictureUrl && !deleteClicked
                            ? (
                                <Box position="relative">
                                    <img
                                        src={driver.pictureUrl}
                                        alt="Driver img"
                                        className={classes.imageBox}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setDeleteClicked(true);
                                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT);
                                        }}
                                        className={classes.deleteIcon}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Box>
                            )
                            : (
                                <ImageUploader
                                    boxWidth="154px"
                                    boxHeight="154px"
                                    isDriverPage
                                    content={
                                        <Grid className={classes.photoUploadContainer}>
                                            <Face className={classes.faceIcon} />
                                            <Typography className={classes.photoUploadDescription}>
                                                {t('pages.rides.frameYourFace')} <span>{t('pages.rides.uploadFile')}</span>
                                            </Typography>
                                        </Grid>
                                    }
                                    setFiles={setFiles}
                                    isLoading={isLoadingImages}
                                    logPhotoInputEvent={() => {
                                        logAmplitudeEvent(ADD_OR_EDIT_DRIVER_PHOTO_INPUT);
                                    }}
                                    logPhotoDeleteEvent={() => {
                                        logAmplitudeEvent(ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT);
                                    }}
                                />
                            )}
                    </Grid>)}
                <Box>
                    <TextField
                        className={classes.formField}
                        error={formErrors.name !== null}
                        helperText={formErrors.name ? t(formErrors.name) : null}
                        label={t('form.fields.nameAndSurname')}
                        name="name"
                        onChange={event => {
                            handleChange('name', event.target.value);
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_NAME_SURNAME_INPUT);
                        }}
                        required
                        autoComplete="off"
                        value={values.name}
                    />
                    <MuiTelInput
                        style={{ marginBottom: formErrors?.mobile !== null ? '1.25rem' : '2.5rem' }}
                        value={values.mobile}
                        defaultCountry={getCountryCode()}
                        label={t('form.fields.phoneNumber')}
                        className={classes.formField}
                        name="mobile"
                        onChange={value => {
                            handleChange('mobile', value);
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_PHONE_NUMBER_INPUT);
                        }}
                        error={formErrors?.mobile !== null}
                        helperText={formErrors?.mobile && t(formErrors.mobile)}
                        variant="filled"
                        fullWidth
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PhoneOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Grid>
        </Modal>
    );
};

export default EditDriverModal;
