import { ModalAction as Modal } from '@get-e/react-components';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RemarksValues, initialRemarks } from '../../../rides/supplier/components/RemarksCard';
import { RideResponse } from '../../api/types';
import RideInformationCard from '../../customer/components/RideInformationCard';
import useConfirmCloseAddRemarksRide from '../hooks/useConfirmCloseAddRemarksRide';
import AdditionalCostsForm from './AdditionalCostsForm';
import ReferenceCard from './ReferenceCard';

const useStyles = makeStyles({ closeRideModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '900px' } } });

export interface CloseRideData {
    referenceNumber: string;
    remarks: string;
}

interface CloseRideModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    ride: RideResponse;
    onFinished: (updatedRide?: RideResponse) => void;
    supplierReference?: string;
}

const CloseRideModal = ({ isModalOpen, onClose, ride, onFinished, supplierReference }: CloseRideModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [remarksValues, setRemarksValues] = useState<RemarksValues>(initialRemarks);
    const [reference, setReference] = useState<string | null>(supplierReference ?? null);
    const [closeRideStage, setCloseRideStage] = useState(0);

    const { onCloseRide, isSaving } = useConfirmCloseAddRemarksRide({
        rideId: ride.unid,
        reference,
        remarksValues,
    });

    const handleClose = async () => {
        if (closeRideStage === 0) {
            setCloseRideStage(1);
            return;
        }

        const data = await onCloseRide();

        onClose();
        onFinished(data);
    };

    const onBackClick = () => {
        if (closeRideStage === 1) {
            setCloseRideStage(0);
            return;
        }

        onClose();
    };

    return (
        <Modal
            modalContentClassName={classes.closeRideModal}
            isOpen={isModalOpen}
            onClose={onBackClick}
            onSubmit={handleClose}
            title={t('pages.rides.closeRide')}
            confirmButtonLabel={closeRideStage === 0 ? t('buttonName.continue') : t('buttonName.closeRide')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            maxWidth="lg"
            fullWidth
        >
            {closeRideStage === 0
                ? (
                    <Grid
                        container
                        style={{
                            width: '100%',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid item xs display="flex" justifyItems="flex-start">
                            <RideInformationCard isSupplierPage data={ride} showUtcSwitch={false} isInModal />
                        </Grid>
                        <Grid item marginTop="1rem">
                            <ReferenceCard
                                isVisible
                                reference={reference}
                                setReference={setReference}
                            />
                        </Grid>
                    </Grid>
                )
                : (
                    <Grid>
                        <AdditionalCostsForm
                            additionalCostsValues={remarksValues}
                            onChangeField={(field, value) => {
                                setRemarksValues({
                                    ...remarksValues,
                                    [field]: value,
                                });
                            }}
                        />
                    </Grid>
                )}
        </Modal>
    );
};

export default CloseRideModal;
