import { Grid, Skeleton, useMediaQuery, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme from '../../../styles/theme';

const useStyles = makeStyles({
    mainContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0 2rem 2rem 2rem',
    },
    mainContainerMobile: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '2rem',
    },
    pageHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000',
    },
    pageHeader: {
        color: '#3f51b5',
        fontSize: '1.5rem',
        fontWeight: 700,
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 263px)',
        marginTop: '2rem',
    },
});

const RidesLoadingSkeleton = () => {
    const classes = useStyles();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container className={isSmallDevice ? classes.mainContainerMobile : classes.mainContainer}>
            <Grid container className={classes.pageHeaderWrapper}>
                <Skeleton variant="text" sx={{ marginRight: '1rem' }} width={isSmallDevice ? '70%' : '20%'} height={40} />
            </Grid>

            <Grid container spacing={1} marginTop="1rem">
                <Grid item xs={12} md={7} xl={7}>
                    <Skeleton variant="rectangular" width="100%" height={60} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4.5}
                    xl={4.5}
                    marginTop={isSmallDevice ? '1.5rem' : '0'}
                    marginLeft={isSmallDevice ? 0 : '2rem'}
                >
                    <Skeleton variant="rectangular" width="100%" height={60} />
                </Grid>
            </Grid>

            <Grid item xs marginTop="2rem">
                <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                    <Skeleton
                        variant="text"
                        width={isSmallDevice ? '15%' : '10%'}
                        height={80}
                        style={{ marginRight: '0.5rem' }}
                    />
                    <Skeleton
                        variant="text"
                        width={isSmallDevice ? '15%' : '10%'}
                        height={80}
                        style={{ marginRight: '0.5rem' }}
                    />
                    <Skeleton
                        variant="text"
                        width={isSmallDevice ? '15%' : '10%'}
                        height={80}
                        style={{ marginRight: '0.5rem' }}
                    />
                    <Skeleton
                        variant="text"
                        width={isSmallDevice ? '15%' : '10%'}
                        height={80}
                        style={{ marginRight: '0.5rem' }}
                    />
                    <Skeleton variant="text" width={isSmallDevice ? '15%' : '10%'} height={80} />
                    <Skeleton
                        sx={{
                            marginLeft: isSmallDevice ? '0' : 'auto',
                            display: isSmallDevice ? 'block' : 'initial',
                            flexBasis: isSmallDevice ? '100%' : 'initial',
                            maxWidth: '200px',

                        }}
                        variant="text"
                        width={isSmallDevice ? '25%' : '10%'}
                        height={60}
                    />
                </Box>

            </Grid>

            <Box className={classes.content}>
                <Skeleton variant="rectangular" width="100%" height="70%" />
            </Box>
        </Grid>
    );
};

export default RidesLoadingSkeleton;
