import { Spinner } from '@get-e/react-components';
import { AirplanemodeActive, AirplanemodeInactive, Language } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../constants/colors';
import withExpandable from '../../components/withExpandable';
import { useFlight } from '../api/useFlight';
import { FlightStatus } from '../helpers/flightStatusUtil';
import AirportDetails from './AirportDetails';
import FlightNotTrackedComponent from './FlightNotTrackedComponent';
import FlightStatusButton from './FlightStatusButton';

const useStyles = makeStyles({
    cardContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        position: 'relative',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
    },
    titleHeader: {
        color: COLORS.BLACK,
        fontWeight: 700,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoContainerMobile: {
        display: 'flex',
        flexDirection: 'column',
    },
    arrivalInfoMobile: { marginTop: '2rem' },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
    },
    bottomText: {
        color: COLORS.SLATE_GREY,
        fontSize: '0.75rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
    },
    googleLink: {
        cursor: 'pointer',
        '& > svg': {
            marginRight: '0.25rem',
            fontSize: '0.875rem',
        },
    },
    planeIcon: {
        color: COLORS.SLATE_GREY,
        marginRight: '0.5rem',
        fontSize: '1.3rem',
    },
    loaderCardContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
});

interface FlightInformationCardProps {
    rideId: string;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const FlightInformationCard = ({ rideId, renderChevrons, isExpanded }: FlightInformationCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery('(min-width:1400px)');
    const { flightDetails, isLoading, isError } = useFlight(rideId);

    const handleGoogleLinkClick = () => {
        if (!flightDetails?.flightNumber) {
            return;
        }

        const query = encodeURIComponent(flightDetails?.flightNumber || '');
        const url = `https://www.google.com/search?q=Flight ${query}`;

        window.open(url, '_blank');
    };

    const notTracked = () => {
        return flightDetails?.flightStatus === FlightStatus.NOT_TRACKED;
    };

    if (isLoading) {
        return (
            <Card variant="outlined" className={classes.cardContainer}>
                <CardContent className={classes.loaderCardContent}>
                    <Spinner size={35} color="primary" />
                </CardContent>
            </Card>
        );
    }

    if (isError) {
        return null;
    }

    return (
        <Card variant="outlined" className={classes.cardContainer}>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <Grid>
                    <Grid className={classes.headingContainer} marginBottom={isExpanded ? '1rem' : 0}>
                        <Typography variant="h3" component="h4" className={classes.heading}>
                            {t('pages.singleRide.flightInformation.title')}
                        </Typography>
                        {renderChevrons}
                    </Grid>
                    {isExpanded && (
                        <Grid item xs={12} className={classes.infoContainer} marginBottom={!isLargeScreen ? '0' : '1rem'}>
                            <Box display="flex">
                                {!notTracked() && (
                                    <Typography component="span" className={classes.titleHeader}>
                                        <Box display="flex" alignItems="center">
                                            <AirplanemodeActive className={classes.planeIcon} />
                                            <span>{flightDetails?.flightNumber}</span>
                                            <FlightStatusButton variant={flightDetails?.flightStatus || ''} />
                                        </Box>
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    )}

                    {isExpanded && (
                        notTracked()
                            ? (
                                <FlightNotTrackedComponent
                                    icon={<AirplanemodeInactive />}
                                    headerText={`${t('pages.singleRide.flightInformation.couldntReturnUpdate')}
                                    ${flightDetails?.flightNumber ? ` (${flightDetails?.flightNumber})` : ''}`}
                                    description={t('pages.singleRide.flightInformation.checkOtherSources')}
                                />
                            )
                            : (
                                <Grid
                                    item
                                    xs={12}
                                    className={isLargeScreen ? classes.infoContainer : classes.infoContainerMobile}
                                >
                                    <AirportDetails
                                        status={flightDetails?.flightStatus}
                                        airportType="departure"
                                        airportData={flightDetails?.departure}
                                        isLargeScreen={isLargeScreen}
                                    />
                                    <AirportDetails
                                        status={flightDetails?.flightStatus}
                                        airportType="arrival"
                                        airportData={flightDetails?.arrival}
                                        isLargeScreen={isLargeScreen}
                                    />
                                </Grid>
                            )
                    )}

                    {isExpanded && (
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Typography className={classes.bottomText}>
                                {t('pages.singleRide.flightInformation.lastUpdate')}: {notTracked()
                                    ? t('pages.singleRide.flightInformation.never')
                                    : dayjs(flightDetails?.updatedAtUTC).fromNow()}
                            </Typography>
                            <Typography
                                onClick={handleGoogleLinkClick}
                                className={clsx(classes.bottomText, classes.googleLink)}
                            >
                                <Language /> {t('pages.singleRide.flightInformation.searchOnGoogle')}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default withExpandable(FlightInformationCard);
