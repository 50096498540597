import { TextField, AddDeleteButtons } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Box, FormControlLabel, InputAdornment, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { COLORS } from '../../../../constants/colors';
import { getCountryCode } from '../../../../helpers/getCountryCode';
import { PassengerError } from './PassengerList';

const useStyles = makeStyles({
    formField: {
        marginBottom: '1rem',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    halfFormField: { width: 'calc(50% - 1rem)' },
    marginLeft: { marginLeft: '2rem' },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem',
    },
});

export interface Passenger {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    uuid?: string;
}

interface PassengerInformationProps {
    passenger?: Passenger;
    handleAddNewPassenger: () => void;
    handleRemovePassenger: (id: string) => void;
    updatePassengers: (passenger: Passenger, error: PassengerError) => void;
    passengerCount: number;
    errorValues?: PassengerError;
    isOnlyOnePassenger?: boolean;
}

const PassengerInformation = ({
    passenger,
    handleAddNewPassenger,
    handleRemovePassenger,
    updatePassengers,
    passengerCount,
    errorValues,
    isOnlyOnePassenger,
}: PassengerInformationProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = useState<Passenger>(
        passenger ?? {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            isLead: false,
        }
    );

    useEffect(() => {
        if (isOnlyOnePassenger) {
            handleChange('isLead', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnlyOnePassenger]);

    const handleChange = <T extends keyof Passenger>(key: T, newValue: Passenger[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        updatePassengers(
            {
                ...values,
                [key]: newValue,
            },
            {
                ...errorValues,
                [key]: null,
            } as PassengerError
        );
    };

    return (
        <>
            <Box className={classes.header}>
                <Typography
                    fontWeight={700}
                    color={COLORS.BLUE_DARK}
                >
                    Passenger {passengerCount}
                </Typography>
                <AddDeleteButtons
                    onAddClick={handleAddNewPassenger}
                    onDeleteClick={() => {
                        handleRemovePassenger(passenger?.id ?? '-1');
                    }}
                    isDeleteDisabled={isOnlyOnePassenger}
                />
            </Box>
            <TextField
                className={clsx(classes.halfFormField, classes.formField)}
                error={errorValues?.firstName !== null}
                helperText={errorValues?.firstName ? t(errorValues.firstName) : null}
                label={t('form.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={clsx(classes.halfFormField, classes.formField, classes.marginLeft)}
                error={errorValues?.lastName !== null}
                helperText={errorValues?.lastName ? t(errorValues.lastName) : null}
                label={t('form.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <MuiTelInput
                style={{ marginBottom: errorValues?.phone !== null ? '1.25rem' : '2.5rem' }}
                value={values.phone}
                defaultCountry={getCountryCode()}
                label={t('form.fields.mobile')}
                className={classes.formField}
                name="mobile"
                onChange={value => handleChange('phone', value)}
                required={values.isLead}
                error={values.isLead && errorValues?.phone !== null}
                helperText={values.isLead && errorValues?.phone ? t(errorValues.phone) : null}
                variant="filled"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PhoneOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.formField}
                error={errorValues?.email !== null}
                helperText={errorValues?.email ? t(errorValues.email) : null}
                label={t('email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required={values.isLead}
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <FormControlLabel
                value={values.id}
                control={<Radio size="small" />}
                label="Main passenger"
                sx={{ marginBottom: '2rem' }}
            />
        </>
    );
};

export default PassengerInformation;
