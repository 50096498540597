import { IconedData } from '@get-e/react-components';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import { Box, Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GoogleMap from '../../../../components/navigation/GoogleMap';
import { COLORS } from '../../../../constants/colors';
import { RideStatus, getRideStatusKey } from '../../../../helpers/rideStatusUtil';
import { PAYMENT_METHOD } from '../../api/types';
import { RideContext } from '../context/RideContext';

interface RideSummaryCardProps {
    isDownloading: boolean;
    sendBookingHandler: () => void;
    downloadBookingHandler: () => void;
    downloadReceiptHandler?: () => void;
    isUtc: boolean;
}

const showGoogleMap = true;

const RideSummaryCard = ({
    isDownloading,
    sendBookingHandler,
    downloadBookingHandler,
    downloadReceiptHandler,
    isUtc,
}: RideSummaryCardProps) => {
    const { t } = useTranslation();

    const {
        summary: {
            status,
            paymentMethod,
            distance,
            time,
            pickupCoordinates,
            dropOffCoordinates,
            pickUpPlaceId,
            dropOffPlaceId,
            booker,
            scheduledArrival,
            scheduledArrivalLocal,
            prettifiedUnid,
            lastPositionUpdate,
        },
    } = useContext(RideContext);

    const points = useMemo(() => {
        return [pickupCoordinates, dropOffCoordinates];
    }, [dropOffCoordinates, pickupCoordinates]);

    return (
        <Grid
            container
            style={{
                width: '100%',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
            }}
        >
            <Grid
                container
                style={{
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    item
                    style={{
                        height: '200px',
                        background: COLORS.EXTRA_LIGHT_GRAY,
                    }}
                >
                    {showGoogleMap && (
                        <GoogleMap
                            points={points}
                            placeIds={[pickUpPlaceId ?? '', dropOffPlaceId ?? '']}
                            subscribeChannelId={`private-trip-${prettifiedUnid}`}
                            driverPosition={lastPositionUpdate}
                        />
                    )}
                    {!showGoogleMap && (
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ExploreOffOutlinedIcon
                                style={{
                                    color: COLORS.SLATE_GREY,
                                    fontSize: '40px',
                                }}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item padding="1rem 1rem 0 1rem">
                    <IconedData
                        icon={RouteOutlinedIcon}
                        data={distance}
                        tooltip={t('distance')}
                        dataLabel={t('distance')}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                    <IconedData
                        icon={TimerOutlinedIcon}
                        data={time}
                        tooltip={t('duration')}
                        dataLabel={t('duration')}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                    <IconedData
                        icon={WhereToVoteOutlinedIcon}
                        data={isUtc ? `${scheduledArrival} (UTC)` : scheduledArrivalLocal}
                        tooltip={t('pages.summary.scheduledDropoff')}
                        dataLabel={`${t('pages.summary.scheduledDropoff')}`}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                    <IconedData
                        icon={AccountCircleOutlinedIcon}
                        data={booker?.name ?? '-'}
                        tooltip={t('pages.summary.bookedBy')}
                        dataLabel={`${t('pages.summary.bookedBy')}`}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                </Grid>
            </Grid>
            <Grid item padding="1.5rem 1rem 1rem 1rem" display="flex" flexDirection="column" alignItems="flex-start">
                <IconedData
                    icon={EmailOutlinedIcon}
                    data={t('pages.rides.sendBookingConfirmation')}
                    bottomMargin="0.5rem"
                    iconColor={COLORS.BLUE_DARK}
                    dataColor={COLORS.BLACK}
                    onClick={sendBookingHandler}
                />
                <IconedData
                    icon={DownloadOutlinedIcon}
                    data={t('pages.rides.downloadBookingConfirmation')}
                    iconColor={COLORS.BLUE_DARK}
                    dataColor={COLORS.BLACK}
                    onClick={isDownloading ? () => {} : downloadBookingHandler}
                />
                {paymentMethod === PAYMENT_METHOD.CREDIT_CARD
                && (status === getRideStatusKey(RideStatus.CONFIRMED) || status === getRideStatusKey(RideStatus.COMPLETED)) && (
                    <IconedData
                        icon={ReceiptLongOutlinedIcon}
                        data={t('pages.rides.downloadReceipt')}
                        iconColor={COLORS.BLUE_DARK}
                        dataColor={COLORS.BLACK}
                        onClick={isDownloading ? () => {} : downloadReceiptHandler}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default RideSummaryCard;
