import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { revokeUser } from '../api';
import { User } from '../api/types';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
});

interface RevokeUserModalProps {
    user: User;
    onClose: () => void;
    onRevokeUser: () => void;
}

const RevokeUserModal = ({ user, onClose, onRevokeUser }: RevokeUserModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { showNotification } = useNotificationContext();

    const handleRevoke = async () => {
        try {
            await revokeUser(user.id);
            onRevokeUser();
        } catch (error) {
            showNotification(t('errors.defaultError'), Severity.Error);
        } finally {
            onClose();
        }
    };

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={handleRevoke}
            title={t('modals.revoke.title')}
            cancelButtonLabel= {t('buttonName.cancel')}
            confirmButtonLabel={t('buttonName.revokeInvite')}
            confirmButtonClassName={classes.confirmButton}
            maxWidth="sm"
        >
            <Typography fontWeight="700">
                {t('modals.revoke.description')}
            </Typography>
            <Typography
                style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem',
                }}
            >
                {t('modals.revoke.descriptionDetailed')}
            </Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginBottom: '2rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default RevokeUserModal;
